import { RootState } from 'rootReducer';

export const selectIsPostingDetails = (state: RootState) =>
  state.ui.apps.survey.verification.home.isPostingDetails;

export const selectSuccessRedirectUrl = (state: RootState) =>
  state.ui.apps.survey.verification.home.redirect_to;

export const selectManualVerificationError = (state: RootState) =>
  state.ui.apps.survey.verification.home.manualVerificationError;
