import { useSelector } from 'react-redux';
import * as React from 'react';

import { RootState } from 'rootReducer';
import { useActions } from 'shared/hooks';
import { getPayment, resetErrorCount } from 'store/payment/actions';
import { selectPaymentRedirectTo, selectErrorCount } from 'store/payment/selectors';
import { useHistory, useParams, useLocation } from 'react-router';
import { ROUTES } from 'shared/routes/constants';
import { redirectToUrl } from './helpers';
import { delay } from 'shared/helpers/misc';

export const usePaymentActions = () =>
  useActions({
    getPayment,
    resetErrorCount,
  });

export const usePaymentSelectors = () =>
  useSelector((state: RootState) => {
    return {
      redirect_to: selectPaymentRedirectTo(state),
      errorCount: selectErrorCount(state),
    };
  });

export const useGetPayment = () => {
  const location = useLocation();
  const { getPayment } = usePaymentActions();
  const { uuid } = useParams<{ uuid: string }>();
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    getPayment({ uuid, paymentId: params.get('payment_id') || undefined });
  }, [uuid, getPayment, location.search]);
};

export const useRetry = () => {
  const { getPayment } = usePaymentActions();
  const { errorCount } = usePaymentSelectors();
  const { uuid } = useParams<{ uuid: string }>();
  React.useEffect(() => {
    (async () => {
      if (errorCount) {
        // delay 45 seconds then retry
        await delay(45 * 1000);
        getPayment({ uuid });
      }
    })();
  }, [uuid, getPayment, errorCount]);
};

export const useResetErrorCountAndRedirectToErrorPayment = () => {
  const history = useHistory();
  const { resetErrorCount } = usePaymentActions();
  const { errorCount } = usePaymentSelectors();
  React.useEffect(() => {
    if (errorCount > 1) {
      resetErrorCount();
      history.push(ROUTES.SURVEY.PATHS.PAYMENT_ERROR);
    }
  }, [resetErrorCount, errorCount]);
};

export const useRedirectToUrl = () => {
  const { redirect_to } = usePaymentSelectors();
  React.useEffect(() => {
    if (redirect_to) {
      redirectToUrl(redirect_to);
    }
  }, [redirect_to]);
};
