import { Map } from 'immutable';

const ROOT = '/';
const SURVEYS_ROOT = '/surveys';
const REFERRAL_ROOT = '/referrals';

export const ROUTES = {
  ROOT,
  SURVEY: {
    PATHS: {
      ROOT: SURVEYS_ROOT,

      ONBOARDING: `${SURVEYS_ROOT}/o/:uuid`,

      MAIN_ENTRY: `${SURVEYS_ROOT}/e/:uuid`,
      REFERRAL_ENTRY: `${REFERRAL_ROOT}/e/:uuid`,

      MAIN_VERIFICATION: `${SURVEYS_ROOT}/v/:uuid`,
      REFERRAL_VERIFICATION: `${REFERRAL_ROOT}/v/:uuid`,

      FRAUD: `${SURVEYS_ROOT}/f/:uuid`,
      QUOTA: `${SURVEYS_ROOT}/q/:uuid`,
      SCREENED: `${SURVEYS_ROOT}/s/:uuid`,
      COMPLETED: `${SURVEYS_ROOT}/c/:uuid`,
      FLAGS: `/flags`,

      SURVEY_CLOSED: `${SURVEYS_ROOT}/survey_closed`,

      PAYMENT_PENDING: `${SURVEYS_ROOT}/payment_pending`,
      PAYMENT_ERROR: `/payments/error`,
      PAYMENT_CLAIM_BY_ID: `/payments/:uuid`,

      VERIFICATION_ACKNOWLEDGEMENT: `${SURVEYS_ROOT}/verification_ack`,
      LINKEDIN_POSTAUTH: `${SURVEYS_ROOT}/linkedin_postauth/:uuid`,

      CUSTOM: `${SURVEYS_ROOT}/:custom_key/:uuid`,

      ERROR: '/error',
      TERMS: '/terms',

      LINKEDIN_POPUP: `${SURVEYS_ROOT}/auth/linkedin/callback`,

      REFERRALS_CLOSED: '/referrals-closed',
    },
  },
};

export const BLOCK_PAGES = [
  ROUTES.SURVEY.PATHS.ONBOARDING,
  ROUTES.SURVEY.PATHS.MAIN_ENTRY,
  ROUTES.SURVEY.PATHS.REFERRAL_ENTRY,
  ROUTES.SURVEY.PATHS.MAIN_VERIFICATION,
  ROUTES.SURVEY.PATHS.REFERRAL_VERIFICATION,
  ROUTES.SURVEY.PATHS.FRAUD,
  ROUTES.SURVEY.PATHS.QUOTA,
  ROUTES.SURVEY.PATHS.SCREENED,
  ROUTES.SURVEY.PATHS.COMPLETED,
  ROUTES.SURVEY.PATHS.CUSTOM,
];

export const NON_BLOCK_PAGES = [
  ROUTES.SURVEY.PATHS.PAYMENT_PENDING,
  ROUTES.SURVEY.PATHS.PAYMENT_ERROR,
  ROUTES.SURVEY.PATHS.PAYMENT_CLAIM_BY_ID,
  ROUTES.SURVEY.PATHS.VERIFICATION_ACKNOWLEDGEMENT,
  ROUTES.SURVEY.PATHS.LINKEDIN_POSTAUTH,
  ROUTES.SURVEY.PATHS.SURVEY_CLOSED,
  ROUTES.SURVEY.PATHS.ERROR,
  ROUTES.SURVEY.PATHS.TERMS,
  ROUTES.SURVEY.PATHS.FLAGS,
  ROUTES.SURVEY.PATHS.LINKEDIN_POPUP,
  ROUTES.SURVEY.PATHS.REFERRALS_CLOSED,
];

export const ALL_PATHS = Map(ROUTES.SURVEY.PATHS)
  .map((path: string) => path)
  .toList()
  .toArray();
