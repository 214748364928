import React from 'react';

import verificationAcknowledgement from 'assets/images/verificationAcknowledgement.svg';
import BlockContainer from 'uicontainers/_shared/container/BlockContainer';
import { Props } from 'uicontainers/_shared/container/BlockContainer/types';

const VerificationAcknowledgement = () => {
  const containerProps: Props = {
    header: 'Thank you for your application!',
    subHeaders: [
      {
        content: `Someone from our team will reach out to you within the next 48 hours if you are a good fit for the survey.`,
      },
      {
        content: `Please look out for an email from our NewtonX Team for your unique survey link. In some cases, it may land in your spam folder.`,
      },
    ],
    image: {
      path: verificationAcknowledgement,
      altText: 'Verification Acknowledgement',
    },
  };

  return <BlockContainer {...containerProps}></BlockContainer>;
};

export default VerificationAcknowledgement;
