import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'shared/routes/constants';
import PaymentClaimUI from 'uicontainers/apps/survey/PaymentClaim';

export const PaymentClaim = () => {
  return (
    <>
      <Switch>
        <Route path={ROUTES.SURVEY.PATHS.PAYMENT_CLAIM_BY_ID} component={PaymentClaimUI} />
      </Switch>
    </>
  );
};

export default PaymentClaim;
