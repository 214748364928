import { RecordOf } from 'immutable';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';

import { getInitialState } from 'shared/helpers/reducer';
import * as storeActions from 'store/onboarding/actions';

import { OnboardingHomeState } from './types';

const defaultValues: OnboardingHomeState = {
  isOnboarding: false,
  redirect_to: null,
};

export const initialState = getInitialState<OnboardingHomeState>(defaultValues);

export default (
  state: RecordOf<OnboardingHomeState> = initialState,
  action: Action,
): RecordOf<OnboardingHomeState> => {
  if (isType(action, storeActions.postOnboarding.async.started)) {
    return state.set('isOnboarding', true);
  }

  if (isType(action, storeActions.postOnboarding.async.done)) {
    return state.set('isOnboarding', false).set('redirect_to', action.payload.result.redirect_to);
  }

  if (isType(action, storeActions.postOnboarding.async.failed)) {
    return state.set('isOnboarding', false);
  }

  return state;
};
