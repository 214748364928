import React from 'react';

import paymentPending from 'assets/images/paymentPending.svg';
import BlockContainer from 'uicontainers/_shared/container/BlockContainer';
import { Props } from 'uicontainers/_shared/container/BlockContainer/types';

const PaymentPending = () => {
  const containerProps: Props = {
    header: 'Payment will arrive shortly',
    subHeaders: [
      {
        content: `Please check your email in the next minute. Email may have landed in your spam folder. Move
        email out of spam to receive payment.`,
      },
      {
        content: `If you see any problems, please email payments@newtonx.com. We're here to help!`,
      },
    ],
    image: {
      path: paymentPending,
      altText: 'Payment Pending',
    },
  };

  return <BlockContainer {...containerProps}></BlockContainer>;
};

export default PaymentPending;
