import React from 'react';

import { OnboardingFormData } from 'api/onboarding/types';
import { Alert, FormItem, Spin } from 'components/misc/helpers';
import HtmlRenderer from 'components/misc/HtmlRenderer';
import { useSurveyContent } from 'shared/hooks';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';
import Handlebars from 'uicontainers/apps/survey/_shared/handlebars/Handlebars';
import Heading from 'uicontainers/_shared/Heading';
import { removeAllHandlebars } from 'shared/helpers/handlebar';

import { getOptInElement, getTermsElement, redirectToUrl } from './helpers';
import styles from './home.module.scss';
import { useHandleSubmit, useOnAlertClose, useOnboardingSelectors } from './hooks';
import NXForm from 'components/misc/NXForm';
import NXButton from 'components/misc/NXButton';

export const Home = () => {
  const [showWarning, setShowWarning] = React.useState(false);
  const { isOnboarding, redirect_to } = useOnboardingSelectors();
  const handleSubmit = useHandleSubmit();

  const { surveyContent } = useSurveyContent();

  const url = window.location.href;

  const onSubmit = (formData: OnboardingFormData) => handleSubmit(formData, setShowWarning);
  const handleOnAlertClose = useOnAlertClose(setShowWarning);

  React.useEffect(() => {
    redirectToUrl(redirect_to as string);
  }, [redirect_to]);

  return (
    <Spin spinning={isOnboarding} size="large">
      <VerticalSpaceContainer>
        {/* heading */}
        <Heading
          text={removeAllHandlebars(surveyContent?.content?.heading ?? null) || ''}
          data-test-id="onboarding-heading"
        />
        {/* subheading */}
        <HtmlRenderer
          content={removeAllHandlebars(surveyContent?.content?.subheading ?? null) || ''}
        ></HtmlRenderer>
        <div className={styles.form}>
          <NXForm
            initialValues={{
              did_opt_in: false,
              did_accept_terms: false,
              survey_request_url: url,
            }}
            autoComplete="off"
            colon={false}
            requiredMark={false}
            labelAlign="left"
            layout="vertical"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSubmit}
          >
            {getTermsElement(surveyContent)}
            {getOptInElement(surveyContent)}

            <FormItem hidden={true} name="survey_request_url"></FormItem>

            <FormItem>
              <NXButton type="primary" htmlType="submit">
                Continue
              </NXButton>
            </FormItem>
          </NXForm>

          {showWarning && (
            <Alert
              message="Please accept terms and conditions to proceed to survey"
              type="warning"
              showIcon
              closable
              onClose={handleOnAlertClose}
              data-test-id="onboarding-alert"
            />
          )}
          <i>
            Disclaimer: Please note that there is a screener section at the beginning of the survey
            (~2-3 min) to ensure that you are qualified to participate in the research. Once you
            complete the screener section, you will enter the main survey section after which you
            will get compensated immediately. If you do not meet the screener requirements or if the
            specific audience group you fall under has been filled, you will be terminated before
            the main survey section which means you are not eligible for compensation. Thank you for
            your understanding. Also note that you can only take this survey once, if you take it
            multiple times you will not be paid for subsequent times. We are happy to continue
            sending you opportunities that could be a fit based on your background and experience.
          </i>
        </div>

        <Handlebars />
      </VerticalSpaceContainer>
    </Spin>
  );
};

export default Home;
