import classNames from 'classnames';
import React from 'react';

import { Alert, Paragraph } from 'components/misc/helpers';
import { sanitize } from 'components/misc/HtmlRenderer/helpers';
import { useIsMobile } from 'shared/hooks';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';

import styles from './formSubmitted.module.scss';
import { useFormSubmittedSelectors } from './hooks';

const FormSubmitted = () => {
  const isMobile = useIsMobile();
  const { paymentInformationResponse } = useFormSubmittedSelectors();
  const alertLevel = paymentInformationResponse?.alert_level ?? 'info';
  const alertMsg = paymentInformationResponse?.alert_msg ?? null;
  const sanitizedAlertMsg = alertMsg ? sanitize(alertMsg) : null;

  return (
    <div
      className={classNames({
        [styles.formSubmitted]: true,
        [styles['is-mobile']]: isMobile,
      })}
    >
      <VerticalSpaceContainer>
        {sanitizedAlertMsg && (
          <Alert
            message={<span dangerouslySetInnerHTML={{ __html: sanitizedAlertMsg }} />}
            type={alertLevel}
            showIcon
          />
        )}

        <Paragraph>
          Your payment email will arrive from professionals@newtonxcommunity.com. If you don’t see
          it in the next few minutes, check your spam folder and move the email into your inbox so
          you don’t miss future payments. For any problems, reach out to
          professionals@newtonxcommunity.com.
        </Paragraph>
      </VerticalSpaceContainer>
    </div>
  );
};

export default FormSubmitted;
