import React from 'react';

import { Title } from 'components/misc/helpers';

import styles from './heading.module.scss';
import { Props } from './types';

const Heading = (props: Props) => {
  const { text } = props;

  return <Title className={styles.title}>{text}</Title>;
};

export default Heading;
