import { useSelector } from 'react-redux';

import { LinkedInPostAuthFormData } from 'api/linkedIn/types';
import { RootState } from 'rootReducer';
import { useActions } from 'shared/hooks';
import { postLinkedInAuth } from 'store/linkedIn/actions';

import { selectIsPostingLinkedInAuth, selectSuccessRedirectUrl } from './selectors';

export const usePostLinkedInAuthActions = () =>
  useActions({
    postLinkedInAuth,
  });

export const useLinkedInPostAuthSelectors = () =>
  useSelector((state: RootState) => {
    return {
      selectIsPostingLinkedInAuth: selectIsPostingLinkedInAuth(state),
      redirect_to: selectSuccessRedirectUrl(state),
    };
  });

export const useHandleSubmit = () => {
  const { postLinkedInAuth: postAction } = usePostLinkedInAuthActions();
  return (formData: LinkedInPostAuthFormData) => {
    postAction(formData);
  };
};
