import { connectRouter } from 'connected-react-router';
// @ts-ignore
import { History } from 'history';
import { combineReducers as _ } from 'redux';

import entry from 'store/entry/reducer';
import onboarding from 'store/onboarding/reducer';
import payment from 'store/payment/reducer';
import linkedIn from 'store/linkedIn/reducer';
import verification from 'store/verification/reducer';
import survey from 'store/survey/reducer';
import surveyReducers from 'uicontainers/apps/survey/surveyReducer';
import featureFlagsReducer from 'uicontainers/apps/survey/SurveyFeatureFlags/reducer';

export const rootReducer = (history: History) =>
  _({
    router: connectRouter(history),
    _resources: _({
      onboarding,
      linkedIn,
      entry,
      survey,
      verification,
      payment,
    }),
    ui: _({
      apps: _({
        survey: surveyReducers,
        flags: featureFlagsReducer,
      }),
    }),
  });

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;

export default rootReducer;
