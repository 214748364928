import React from 'react';
import Button from 'antd/lib/button';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Typography from 'antd/lib/typography';
import notFound from 'assets/images/notFound.svg';
import { useGoBack } from './hooks';
import styles from './notFound.module.scss';

const NotFound = () => {
  const { Title } = Typography;
  const handleBack = useGoBack();

  return (
    <Row className={styles.container}>
      <Col>
        <img className={styles.image} src={notFound} alt="Not found - 404 page" />
      </Col>
      <Col className={styles.rightCol}>
        <Title level={1}>404</Title>
        <Title level={5}>Sorry, something went wrong</Title>
        <Button type="primary" onClick={handleBack}>
          Go Back
        </Button>
      </Col>
    </Row>
  );
};

export default NotFound;
