import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import { useActions } from 'shared/hooks';
import { RootState } from 'rootReducer';

import { getContent } from 'store/survey/actions';
import { ROUTES } from 'shared/routes/constants';

import {
  selectIsAuthorized,
  selectIsAuthorizing,
  selectRedirectTo,
  selectBlockType,
  selectContent,
} from './selectors';

import * as topLevelLoadingSpinnerSelectors from 'uicontainers/apps/survey/_shared/TopLevelLoadingSpinner/selectors';

export const useSurveyRouteConfigActions = () =>
  useActions({
    getContent,
  });

export const useSurveyRouteConfigSelectors = () =>
  useSelector((state: RootState) => {
    return {
      isAuthorized: selectIsAuthorized(state),
      isAuthorizing: selectIsAuthorizing(state),
      redirectTo: selectRedirectTo(state),
      blockType: selectBlockType(state),
      content: selectContent(state),
      shouldShowTopLevelLoadingSpinner: topLevelLoadingSpinnerSelectors.selectShouldShowTopLevelLoadingSpinner(
        state,
      ),
    };
  });

export const useAuthorize = () => {
  const { getContent } = useSurveyRouteConfigActions();
  const { redirectTo } = useSurveyRouteConfigSelectors();
  const url = window.location.href;
  const history = useHistory();

  // Necessary to trigger re-render if location changes
  useLocation();

  const fetchContentAndRedirectIfNeeded = async (url: string) => {
    const parts = url.split('/');
    const uuidValidParts = parts.map((part) => uuidValidate(part)).filter((isValid) => isValid);

    if (uuidValidParts.length < 1) {
      history.push(ROUTES.SURVEY.PATHS.ERROR);
      return;
    }

    try {
      await getContent({ url });
    } catch (e) {
      const error = e as AxiosError;
      if (error && error.response?.status === 500) {
        history.push(ROUTES.SURVEY.PATHS.ERROR);
      }
    }
  };

  useEffect(() => {
    fetchContentAndRedirectIfNeeded(url);
  }, [url]);

  useEffect(() => {
    if (redirectTo) {
      let newRedirectTo = redirectTo;
      if (process.env.REACT_APP_ENV === 'local') {
        newRedirectTo = redirectTo.replace('localhost', 'localhost:3001');
      }
      if (newRedirectTo !== url) {
        window.location.assign(newRedirectTo);
      } else if (newRedirectTo === url) {
        history.push(ROUTES.SURVEY.PATHS.ERROR);
      }
    }
  }, [redirectTo, url]);
};
