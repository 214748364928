import React from 'react';

import { Spin } from 'components/misc/helpers';
import Heading from 'uicontainers/_shared/Heading';

import { useEntryActions, useEntrySelectors } from './hooks';

export const Home = () => {
  const { getEntry } = useEntryActions();
  const { isVerifying } = useEntrySelectors();

  React.useEffect(() => {
    getEntry(window.location.href);
  }, []);

  return (
    <Spin spinning={isVerifying} size="large">
      <Heading text="Please wait" />
    </Spin>
  );
};

export default Home;
