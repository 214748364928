import { RootState } from 'rootReducer';

export const selectIsAuthorized = (state: RootState) =>
  state._resources.survey.content?.is_allowed ?? null;

export const selectIsAuthorizing = (state: RootState) => !state._resources.survey.content;

export const selectRedirectTo = (state: RootState) =>
  state._resources.survey.content?.redirect_to ?? null;

export const selectBlockType = (state: RootState) =>
  state._resources.survey.content?.block_type ?? null;

export const selectContent = (state: RootState) => state._resources.survey.content?.content ?? null;
