import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'shared/routes/constants';
import VerificationAcknowledgementUI from 'uicontainers/apps/survey/VerificationAcknowledgement';

export const VerificationAcknowledgement = () => {
  return (
    <>
      <Switch>
        <Route
          path={ROUTES.SURVEY.PATHS.VERIFICATION_ACKNOWLEDGEMENT}
          component={VerificationAcknowledgementUI}
        />
      </Switch>
    </>
  );
};

export default VerificationAcknowledgement;
