import { useSelector } from 'react-redux';

import { RootState } from 'rootReducer';
import { useActions } from 'shared/hooks';
import { getEntry } from 'store/entry/actions';

import { selectIsVerifying } from './selectors';

export const useEntryActions = () =>
  useActions({
    getEntry,
  });

export const useEntrySelectors = () =>
  useSelector((state: RootState) => {
    return {
      isVerifying: selectIsVerifying(state),
    };
  });
