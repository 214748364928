import React from 'react';

import {
  useGetPayment,
  useRetry,
  useResetErrorCountAndRedirectToErrorPayment,
  usePaymentSelectors,
  useRedirectToUrl,
} from './hooks';
import { Spin } from 'components/misc/helpers';

import styles from './paymentClaim.module.scss';

const PaymentClaim = () => {
  useGetPayment();
  useRetry();
  useResetErrorCountAndRedirectToErrorPayment();
  useRedirectToUrl();

  const { errorCount } = usePaymentSelectors();

  return (
    <div className={styles.container}>
      <div className={styles.spinner}>
        {errorCount > 0 && <p>Stay here , we will try in 45 seconds again!</p>}
        <Spin size="large" />
      </div>
    </div>
  );
};

export default PaymentClaim;
