import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'shared/routes/constants';
import SurveyClosedUI from 'uicontainers/apps/survey/SurveyClosed';

export const SurveyClosed = () => {
  return (
    <>
      <Switch>
        <Route path={ROUTES.SURVEY.PATHS.SURVEY_CLOSED} component={SurveyClosedUI} />
      </Switch>
    </>
  );
};

export default SurveyClosed;
