import { ActionCreatorsMapObject, bindActionCreators } from 'redux';
import { DependencyList, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useMediaQuery } from 'react-responsive';
import { BREAKPOINT } from 'shared/constants/responsive';

import { RootState } from 'rootReducer';

import { selectSurveyContent } from 'store/survey/selectors';

export const useActions = <TAction, T extends ActionCreatorsMapObject<TAction>>(
  actions: T,
  deps: DependencyList = [],
) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch, ...deps]);
};

export const usePushAction = (path: string) => {
  const history = useHistory();
  return () => history.push(path);
};

export const usePushActionWithId = <T>(
  path: (id: number) => string,
  idGetter: (record: T) => number,
) => {
  const history = useHistory();
  return useCallback(
    (record: T) => {
      history.push(path(idGetter(record)));
    },
    [history, path, idGetter],
  );
};

export const useIsMobile = () => useMediaQuery({ query: `(max-width: ${BREAKPOINT}px)` });

export const useSurveyContent = () =>
  useSelector((state: RootState) => {
    return {
      surveyContent: selectSurveyContent(state)?.toJSON() ?? null,
    };
  });
