import React from 'react';

import HtmlRenderer from 'components/misc/HtmlRenderer';
import { useSurveyContent } from 'shared/hooks';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';
import Heading from 'uicontainers/_shared/Heading';
import Handlebars from 'uicontainers/apps/survey/_shared/handlebars/Handlebars';

import { removeAllHandlebars } from 'shared/helpers/handlebar';

const CustomBlock = () => {
  const { surveyContent } = useSurveyContent();

  const heading = removeAllHandlebars(surveyContent?.content?.heading ?? null) ?? '';
  const subheading = removeAllHandlebars(surveyContent?.content?.subheading ?? null) ?? '';

  return (
    <VerticalSpaceContainer>
      <Heading text={heading} />

      <HtmlRenderer content={subheading} />

      <Handlebars />
    </VerticalSpaceContainer>
  );
};

export default CustomBlock;
