import { Rule } from 'antd/lib/form';

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 8 },
    lg: { span: 8 },
  },
};

export const EMAIL_RULES: Rule[] = [
  {
    type: 'email',
    message: 'The input is not valid Email!',
  },
  {
    required: true,
    message: 'Work Email is required',
  },
];

export const VALID_WEBSITE_PATTERN = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
);

export const VALID_PROTOCOL_PATTERN = new RegExp(/(http(s?)):\/\//i);

export const WEBSITE_RULES: Rule[] = [
  {
    pattern: VALID_WEBSITE_PATTERN,
    message: 'Please put a valid domain',
  },
  {
    pattern: VALID_PROTOCOL_PATTERN,
    message: 'Please include http:// or https:// in the company website',
  },
  {
    required: true,
    message: 'Company Website is required',
  },
];

export const REQUIRED_RULE = (fieldName: string) => {
  return [
    {
      required: true,
      whitespace: true,
      message: `${fieldName} is required`,
    },
  ];
};
