import classNames from 'classnames';
import React from 'react';

import referralsImagePath from 'assets/images/referrals.svg';
import referrals2ImagePath from 'assets/images/referrals2.svg';
import { Tooltip, TooltipProps } from 'components/misc/helpers';
import HtmlRenderer from 'components/misc/HtmlRenderer';
import { hasHandlebar, removeAllHandlebars } from 'shared/helpers/handlebar';
import { useIsMobile, useSurveyContent } from 'shared/hooks';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';
import FormSubmitted from 'uicontainers/apps/survey/_shared/handlebars/FormSubmitted';
import ShowForm from 'uicontainers/apps/survey/_shared/handlebars/ShowForm';

import {
  CopyReferralLinkButtonRegex,
  MoreDetailsReferralButtonRegex,
  PaymentEmailEntryHandlebarRegex,
} from './constants';
import { useHandleCopyLink, useRedirect, useHandlebarsSelectors, useReferralLink } from './hooks';
import styles from './handlebars.module.scss';
import NXButton from 'components/misc/NXButton';

const Handlebars = () => {
  const isMobile = useIsMobile();
  const handleCopyLink = useHandleCopyLink();
  const referralLink = useReferralLink();
  const { paymentInformationResponse, copiedReferralLink } = useHandlebarsSelectors();
  const { surveyContent } = useSurveyContent();

  const hasPaymentEmailEntryHandlebar =
    hasHandlebar(surveyContent?.content?.subheading ?? null, PaymentEmailEntryHandlebarRegex) ||
    hasHandlebar(surveyContent?.content?.heading ?? null, PaymentEmailEntryHandlebarRegex);

  const hasCopyReferralLinkButtonHandlebar =
    hasHandlebar(surveyContent?.content?.referral_message ?? null, CopyReferralLinkButtonRegex) ||
    hasHandlebar(surveyContent?.content?.subheading ?? null, CopyReferralLinkButtonRegex) ||
    hasHandlebar(surveyContent?.content?.heading ?? null, CopyReferralLinkButtonRegex);

  const hasMoreDetailsReferralButtonHandlebar =
    hasHandlebar(
      surveyContent?.content?.referral_message ?? null,
      MoreDetailsReferralButtonRegex,
    ) ||
    hasHandlebar(surveyContent?.content?.subheading ?? null, MoreDetailsReferralButtonRegex) ||
    hasHandlebar(surveyContent?.content?.heading ?? null, MoreDetailsReferralButtonRegex);

  const referralMessage = removeAllHandlebars(surveyContent?.content?.referral_message ?? null);

  const shouldRenderForm = !paymentInformationResponse && hasPaymentEmailEntryHandlebar;

  const shouldRenderFormSubmitted =
    paymentInformationResponse && !paymentInformationResponse?.redirect_to;

  const shouldRender = !paymentInformationResponse || shouldRenderFormSubmitted;
  const shouldRenderRectangle =
    referralMessage || hasCopyReferralLinkButtonHandlebar || hasMoreDetailsReferralButtonHandlebar;

  const tooltipProps: TooltipProps = {
    title: 'Copied to Clipboard',
    placement: 'top',
    visible: false,
  };

  if (copiedReferralLink) {
    tooltipProps.visible = true;
  }

  useRedirect();

  return shouldRender ? (
    <div
      className={classNames({
        [styles.handlebars]: true,
        [styles['is-mobile']]: isMobile,
      })}
    >
      <VerticalSpaceContainer>
        {shouldRenderForm && <ShowForm />}

        {shouldRenderFormSubmitted && <FormSubmitted />}

        {shouldRenderRectangle && (
          <div className={styles.rectangle}>
            <div className={styles.rectangleLeftSection}>
              <VerticalSpaceContainer>
                {referralMessage && <HtmlRenderer content={referralMessage} />}

                {hasCopyReferralLinkButtonHandlebar && (
                  <>
                    <a href={referralLink}>{referralLink}</a>
                    <Tooltip {...tooltipProps}>
                      <NXButton className={styles.copyLink} type="primary" onClick={handleCopyLink}>
                        Copy Link
                      </NXButton>
                    </Tooltip>
                  </>
                )}
              </VerticalSpaceContainer>
            </div>

            {!isMobile && (
              <div className={styles.rectangleRightSection}>
                {paymentInformationResponse ? (
                  <img src={referrals2ImagePath} alt="referral" />
                ) : (
                  <img src={referralsImagePath} alt="referral" />
                )}
              </div>
            )}
          </div>
        )}
      </VerticalSpaceContainer>
    </div>
  ) : null;
};

export default Handlebars;
