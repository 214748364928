import { Record, RecordOf } from 'immutable';

import { Payment } from 'api/payment/types';

export const paymentInitializer = (payment: Payment): RecordOf<Payment> => {
  const defaultValues: Payment = {
    redirect_to: '',
  };
  return Record<Payment>(defaultValues)(payment);
};
