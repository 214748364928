import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { Record, RecordOf } from 'immutable';

import { SendPaymentInformationResponse } from 'api/surveys/types';

import { paymentInformationResponseInitializer } from 'api/surveys/helpers';

import { SurveyHandlebarsState } from './types';

import * as actions from './actions';

const defaultValues: SurveyHandlebarsState = {
  paymentInformationResponse: null,
  copiedReferralLink: false,
  paymentInformationErrorResponse: null,
  sendPaymentInformationStatus: 'initial',
};

export const stateInitializer = Record<SurveyHandlebarsState>(defaultValues);
export const initialState: RecordOf<SurveyHandlebarsState> = stateInitializer();

export default (
  state: RecordOf<SurveyHandlebarsState> = initialState,
  action: Action,
): RecordOf<SurveyHandlebarsState> => {
  if (isType(action, actions.sendPaymentInformation.async.started)) {
    return state
      .set('paymentInformationResponse', null)
      .set('sendPaymentInformationStatus', 'started');
  } else if (isType(action, actions.sendPaymentInformation.async.done)) {
    const response = action.payload.result;

    return state
      .set('paymentInformationResponse', paymentInformationResponseInitializer(response))
      .set('sendPaymentInformationStatus', 'done');
  } else if (isType(action, actions.sendPaymentInformation.async.failed)) {
    const response = action.payload.error;

    return state
      .set(
        'paymentInformationErrorResponse',
        paymentInformationResponseInitializer(response as SendPaymentInformationResponse),
      )
      .set('sendPaymentInformationStatus', 'failed');
  } else if (isType(action, actions.showCopiedReferralLink)) {
    return state.set('copiedReferralLink', action.payload);
  }

  return state;
};
