import React, { FC } from 'react';

import { Button } from 'components/misc/helpers';

import { Props } from './types';

const NXButton: FC<Props> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Button {...otherProps} size="large">
      {children}
    </Button>
  );
};

export default NXButton;
