import classNames from 'classnames';
import React from 'react';

import { PhoneNumberFormItem } from 'components/form';
import { Alert, Checkbox, FormItem, Input, useForm } from 'components/misc/helpers';
import { removeAllHandlebars } from 'shared/helpers/handlebar';
import { useIsMobile, useSurveyContent } from 'shared/hooks';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';

import { formItemLayout } from './constants';
import { useHandleSubmit, useShowFormSelectors } from './hooks';
import styles from './showForm.module.scss';
import { FormData } from './types';
import NXButton from 'components/misc/NXButton';
import NXForm from 'components/misc/NXForm';

const ShowForm = () => {
  const [form] = useForm<FormData>();
  const handleSubmit = useHandleSubmit();
  const isMobile = useIsMobile();
  const { surveyContent } = useSurveyContent();
  const { paymentInformationErrorResponse, paymentInformationStatus } = useShowFormSelectors();
  const optInMessage = removeAllHandlebars(surveyContent?.content?.opt_in_message ?? null);
  const shouldShowOptIn = Boolean(surveyContent?.ui_specs?.should_show_opt_in && optInMessage);

  const alertLevel = paymentInformationErrorResponse?.alert_level ?? 'info';
  const alertMsg = paymentInformationErrorResponse?.alert_msg ?? null;
  return (
    <div
      className={classNames({
        [styles.showForm]: true,
        [styles['is-mobile']]: isMobile,
      })}
    >
      <VerticalSpaceContainer>
        {alertMsg && <Alert message={alertMsg} type={alertLevel} showIcon />}

        <NXForm
          form={form}
          onFinish={handleSubmit}
          requiredMark={true}
          colon={false}
          layout="vertical"
          {...formItemLayout}
          initialValues={{
            optIn: false,
            countryCodeId: '201', // United States of America
          }}
        >
          <FormItem
            label="E-mail"
            name="email"
            required
            className={styles.email}
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input your (valid) email',
              },
            ]}
          >
            <Input disabled={paymentInformationStatus === 'started'} autoComplete="new-password" />
          </FormItem>
          <FormItem
            label="Confirm Email"
            name="confirmEmail"
            required
            dependencies={['email']}
            className={styles.confirmEmail}
            rules={[
              {
                required: true,
                message: 'Please input your (valid) email',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject('Please make sure email matches');
                },
              }),
            ]}
          >
            <Input disabled={paymentInformationStatus === 'started'} autoComplete="new-password" />
          </FormItem>

          <PhoneNumberFormItem
            label="Phone"
            name="phone"
            countrySelectorName="countryCodeId"
            disabled={paymentInformationStatus === 'started'}
          />

          {shouldShowOptIn && (
            <FormItem name="optIn" valuePropName="checked">
              <Checkbox>{optInMessage}</Checkbox>
            </FormItem>
          )}

          <FormItem>
            <NXButton
              className={styles.submit}
              type="primary"
              htmlType="submit"
              loading={paymentInformationStatus === 'started'}
            >
              Submit
            </NXButton>
          </FormItem>
        </NXForm>
      </VerticalSpaceContainer>
    </div>
  );
};

export default ShowForm;
