import React, { PureComponent } from 'react';
import Button from 'antd/lib/button';
import Result from 'antd/lib/result';
import { Props } from './types';

class BrowserStorageHandler extends PureComponent<Props> {
  state = {
    hasError: false,
  };
  constructor(props: Props) {
    super(props);
    let hasError = false;
    try {
      window.sessionStorage.getItem('exists'); // eslint-disable-line
      window.localStorage.getItem('exists'); // eslint-disable-line
      hasError = false;
    } catch (e) {
      hasError = true;
    }
    this.state = { hasError };
  }

  refresh = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="500"
          title="Error"
          subTitle="Sorry, but it seems that your browsers blocks cookies & session storage. Please check your browser settings and refresh the page."
          extra={
            <Button type="primary" onClick={this.refresh}>
              Refresh
            </Button>
          }
        />
      );
    }
    return this.props.children;
  }
}

export default BrowserStorageHandler;
