import { useSelector } from 'react-redux';

import { OnboardingFormData } from 'api/onboarding/types';
import { RootState } from 'rootReducer';
import { useActions } from 'shared/hooks';
import { postOnboarding } from 'store/onboarding/actions';

import { selectIsOnboarding, selectSuccessRedirectUrl } from './selectors';

export const useOnboardingActions = () =>
  useActions({
    postOnboarding,
  });

export const useOnboardingSelectors = () =>
  useSelector((state: RootState) => {
    return {
      isOnboarding: selectIsOnboarding(state),
      redirect_to: selectSuccessRedirectUrl(state),
    };
  });

export const useHandleSubmit = () => {
  const { postOnboarding: postAction } = useOnboardingActions();
  return (
    formData: OnboardingFormData,
    showWaring: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (formData.did_accept_terms === undefined || formData.did_accept_terms) {
      showWaring(false);
      postAction(formData);
    } else {
      showWaring(true);
    }
  };
};

export const useOnAlertClose = (showWaring: React.Dispatch<React.SetStateAction<boolean>>) => {
  return () => {
    return showWaring(false);
  };
};
