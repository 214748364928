import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'shared/routes/constants';
import PaymentErrorUI from 'uicontainers/apps/survey/PaymentError';

export const PaymentError = () => {
  return (
    <>
      <Switch>
        <Route path={ROUTES.SURVEY.PATHS.PAYMENT_ERROR} component={PaymentErrorUI} />
      </Switch>
    </>
  );
};

export default PaymentError;
