import React from 'react';

import error from 'assets/images/error.svg';
import BlockContainer from 'uicontainers/_shared/container/BlockContainer';
import { Props } from 'uicontainers/_shared/container/BlockContainer/types';

const Error = () => {
  const containerProps: Props = {
    header: 'Oops! Something went wrong.',
    subHeaders: [
      {
        content: `Looks like something unexpected happened. Please retry the link in 1 minute. If you have any
        additional issues or if that does not work, reply to the email and we will look into it.`,
      },
    ],
    image: {
      path: error,
      altText: 'Error',
    },
  };

  return <BlockContainer {...containerProps}></BlockContainer>;
};

export default Error;
