import { RecordOf } from 'immutable';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';

import { getInitialState } from 'shared/helpers/reducer';
import * as storeActions from 'store/linkedIn/actions';

import { LinkedInPostAuthState } from './types';

const defaultValues: LinkedInPostAuthState = {
  isPostingLinedInAuth: false,
  redirect_to: null,
};

export const initialState = getInitialState<LinkedInPostAuthState>(defaultValues);

export default (
  state: RecordOf<LinkedInPostAuthState> = initialState,
  action: Action,
): RecordOf<LinkedInPostAuthState> => {
  if (isType(action, storeActions.postLinkedInAuth.async.started)) {
    return state.set('isPostingLinedInAuth', true);
  }

  if (isType(action, storeActions.postLinkedInAuth.async.done)) {
    return state
      .set('isPostingLinedInAuth', false)
      .set('redirect_to', action.payload.result.redirect_to);
  }

  if (isType(action, storeActions.postLinkedInAuth.async.failed)) {
    return state.set('isPostingLinedInAuth', false);
  }

  return state;
};
