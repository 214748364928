import React from 'react';

import { SurveyContent } from 'api/surveys/types';
import { Checkbox, FormItem } from 'components/misc/helpers';
import HtmlRenderer from 'components/misc/HtmlRenderer';

export const redirectToUrl = (redirect_to: string) => {
  redirect_to && window.location.assign(redirect_to);
};

export const getTermsElement = (surveyContent: SurveyContent | null) => {
  return (
    surveyContent?.ui_specs &&
    surveyContent?.ui_specs['should_show_terms'] && (
      <FormItem name="did_accept_terms" valuePropName="checked">
        <Checkbox data-test-id="onboarding-chk-terms">
          <HtmlRenderer content={surveyContent?.content?.terms_message || ''}></HtmlRenderer>
        </Checkbox>
      </FormItem>
    )
  );
};

export const getOptInElement = (surveyContent: SurveyContent | null) => {
  const optInMessage = surveyContent?.content?.opt_in_message;

  return (
    surveyContent?.ui_specs &&
    surveyContent?.ui_specs['should_show_opt_in'] &&
    optInMessage && (
      <FormItem name="did_opt_in" valuePropName="checked">
        <Checkbox data-test-id="onboarding-chk-opt-in">{optInMessage}</Checkbox>
      </FormItem>
    )
  );
};
