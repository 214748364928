import React from 'react';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { useParams } from 'react-router-dom';

import { replaceURLDomainToBrowserDomain } from 'api/base/helpers';
import { Space } from 'components/misc/helpers';
import BlockContainer from 'uicontainers/_shared/container/BlockContainer';
import { Props as ContainerProps } from 'uicontainers/_shared/container/BlockContainer/types';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';

import { renderElement } from './helpers';
import { useHandleFailure, useHandleSuccess } from './hooks';
import { LinkedInFailure, LinkedInSuccess, ParamTypes, Props } from './types';
import NXButton from 'components/misc/NXButton';

export const SignIn = (props: Props) => {
  const { doNotHaveLinkedInClickHandler } = props;
  const linkedInClientId = process.env.REACT_APP_LINKED_IN_CLIENT_ID;
  const redirectUrl = encodeURIComponent(
    replaceURLDomainToBrowserDomain(process.env.REACT_APP_LINKED_IN_CALLBACK_URL as string),
  );

  const { uuid } = useParams<ParamTypes>();

  const containerProps: ContainerProps = {
    header: 'Before we begin',
    subHeaders: [
      {
        content: <>We use LinkedIn to make this process easier. Please sign in to start survey.</>,
      },
    ],
  };

  const handleSuccess = useHandleSuccess(props, uuid, redirectUrl);
  const onSuccess = (data: LinkedInSuccess) => handleSuccess(data);

  const handleFailure = useHandleFailure();
  const onFailure = (error: LinkedInFailure) => handleFailure(error);

  return (
    <VerticalSpaceContainer>
      <BlockContainer {...containerProps}></BlockContainer>
      <Space wrap={true} style={{ margin: '0px' }}>
        <LinkedIn
          clientId={linkedInClientId}
          onFailure={onFailure}
          scope="r_liteprofile r_emailaddress"
          onSuccess={onSuccess}
          redirectUri={redirectUrl}
          renderElement={renderElement}
        ></LinkedIn>
        <NXButton onClick={doNotHaveLinkedInClickHandler}>I do not have LinkedIn</NXButton>
      </Space>
    </VerticalSpaceContainer>
  );
};

export default SignIn;
