import { CloseCircleOutlined } from '@ant-design/icons';
import { datadogRum } from '@datadog/browser-rum';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Space from 'antd/lib/space';
import React, { ErrorInfo } from 'react';

import styles from './errorBoundary.module.scss';

import { Props, State } from './types';
import { formatExceptionForCapture } from '../../../shared/helpers/misc';

const TextArea = Input.TextArea;

class ErrorBoundary extends React.PureComponent<Props, State> {
  static state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      pathname: window.location.pathname,
      error: null,
      info: null,
      userProvidedMessage: '',
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(error);
    console.error(info);
    this.setState({ hasError: true, error, info });
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const userProvidedMessage: string = (event && event.target && event.target.value) || '';
    this.setState({ userProvidedMessage });
  };

  handleSubmitOrCancel = () => {
    const { error, info } = this.state;
    this.setState({ hasError: false });
    // @ts-ignore
    datadogRum.addError(formatExceptionForCapture(error), info);
  };

  render() {
    if (this.state.hasError) {
      return (
        <Modal
          onCancel={this.handleSubmitOrCancel}
          title={
            <div className={styles.title}>
              <Space>
                {/*// @ts-ignore */}
                <CloseCircleOutlined style={{ color: 'red' }} />
                Something went wrong...
              </Space>
            </div>
          }
          className={styles.container}
          visible
          footer={[
            <Button type="primary" key="1" onClick={this.handleSubmitOrCancel}>
              Submit
            </Button>,
          ]}
        >
          <div className={styles.content}>
            <TextArea
              autoSize={{ minRows: 8 }}
              placeholder="Please provide any additional information below"
              onChange={this.handleChange}
            />
          </div>
        </Modal>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
