import React from 'react';

import { Space } from 'components/misc/helpers';

import styles from './verticalSpaceContainer.module.scss';

export const VerticalSpaceContainer = (props: React.PropsWithChildren<object>) => {
  const { children } = props;
  return (
    <Space direction="vertical" size="middle" className={styles.container}>
      {children}
    </Space>
  );
};

export default VerticalSpaceContainer;
