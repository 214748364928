import { fromJS } from 'immutable';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './rootReducer';
import { persistStore } from 'redux-persist';
// @ts-ignore
import { createBrowserHistory } from 'history';

const thunk: ThunkMiddleware<object> = thunkMiddleware;

const composeEnhancers = composeWithDevTools({
  name: 'nx-survey-portal-ui',
});

export const history = createBrowserHistory({});

let middleware;

const { NODE_ENV } = process.env;

if (['local', 'development'].includes(NODE_ENV)) {
  // @ts-ignore
  const stateTransformer = (state) => {
    const newState = {};

    // Transform nested immutables to plain js
    for (const i of Object.keys(state)) {
      // @ts-ignore
      newState[i] = fromJS(state[i]).toJS();
    }

    return newState;
  };
  const logger = createLogger({ stateTransformer, collapsed: true });
  middleware = composeEnhancers(applyMiddleware(routerMiddleware(history), thunk, logger));
} else {
  middleware = applyMiddleware(routerMiddleware(history), thunk);
}

export const store = createStore(rootReducer(history), middleware);
export const persistor = persistStore(store);
