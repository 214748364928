import { Record, RecordOf } from 'immutable';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';

import * as storeActions from 'store/verification/actions';

import { VerificationHomeState, ManualError } from './types';

const defaultValues: VerificationHomeState = {
  isVerifyingLinkedInOAuth: false,
  isPostingDetails: false,
  redirect_to: null,
  manualVerificationError: null,
};

export const stateInitializer = Record<VerificationHomeState>(defaultValues);
export const initialState: RecordOf<VerificationHomeState> = stateInitializer();

export default (
  state: RecordOf<VerificationHomeState> = initialState,
  action: Action,
): RecordOf<VerificationHomeState> => {
  if (isType(action, storeActions.verifyLinkedInOAuth.async.started)) {
    return state.set('isVerifyingLinkedInOAuth', true);
  }

  if (isType(action, storeActions.verifyLinkedInOAuth.async.done)) {
    return state.set('isVerifyingLinkedInOAuth', false);
  }

  if (isType(action, storeActions.verifyLinkedInOAuth.async.failed)) {
    return state.set('isVerifyingLinkedInOAuth', false);
  }

  if (isType(action, storeActions.postDetails.async.started)) {
    return state.set('isPostingDetails', true);
  }

  if (isType(action, storeActions.postDetails.async.done)) {
    return state
      .set('isPostingDetails', false)
      .set('redirect_to', action.payload.result.redirect_to);
  }

  if (isType(action, storeActions.postDetails.async.failed)) {
    const response = action.payload.error;
    return state
      .set('isPostingDetails', false)
      .set('manualVerificationError', response as ManualError);
  }

  return state;
};
