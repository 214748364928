import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'shared/routes/constants';
import surveyFeatureFlags from 'uicontainers/apps/survey/SurveyFeatureFlags';

export const Flags = () => {
  return (
    <>
      <Switch>
        <Route path={ROUTES.SURVEY.PATHS.FLAGS} component={surveyFeatureFlags} />
      </Switch>
    </>
  );
};

export default Flags;
