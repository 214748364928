import React, { FC } from 'react';

import { FormItem, Select, Option } from 'components/misc/helpers';

import { countries } from './constants';

import { Props } from './types';

import styles from './countrySelector.module.scss';

const CountrySelector: FC<Props> = (props) => {
  const { name } = props;

  return (
    <FormItem
      className={styles.countrySelector}
      name={name}
      rules={[{ required: true, message: 'Please select a country code' }]}
      noStyle
    >
      <Select
        className={styles.select}
        dropdownMatchSelectWidth={false}
        onFocus={(event) => {
          event.target.setAttribute('autocomplete', 'empty');
        }}
        filterOption={(input, option) => {
          const key: string | null = (option?.key as string) ?? '';

          return key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        showSearch
        optionLabelProp="label"
      >
        {countries.map(({ id, countryCallingCode, countryNameEn, flag }) => (
          <Option
            key={`${id}:${countryNameEn}`}
            value={id}
            label={`${flag} +${countryCallingCode}`}
          >
            {flag} {countryNameEn} +{countryCallingCode}
          </Option>
        ))}
      </Select>
    </FormItem>
  );
};

export default CountrySelector;
