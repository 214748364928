import React from 'react';

import { Paragraph } from 'components/misc/helpers';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';
import Heading from 'uicontainers/_shared/Heading';

import styles from './blockContainer.module.scss';
import { Props } from './types';

const BlockContainer = (props: Props) => {
  const { header, subHeaders, image } = props;

  return (
    <VerticalSpaceContainer>
      <Heading text={header} />

      {subHeaders.map((subHeader, index) => (
        <Paragraph
          {...(subHeader.cssClassNames ? { className: subHeader.cssClassNames } : {})}
          {...(subHeader.type ? { type: subHeader.type } : {})}
          key={`${subHeader.content}-${index}`}
        >
          {subHeader.content}
        </Paragraph>
      ))}

      {image && image.path && (
        <div>
          <img className={styles.image} src={image.path} alt={image.altText} />
        </div>
      )}
    </VerticalSpaceContainer>
  );
};

export default BlockContainer;
