import React from 'react';

import { sanitize } from './helpers';

import { Props } from './types';

export const HtmlRenderer = (props: Props) => {
  const { content } = props;
  return <div dangerouslySetInnerHTML={{ __html: sanitize(content) || '' }}></div>;
};

export default HtmlRenderer;
