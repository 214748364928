import * as verification from 'api/verification';
import {
  DetailsFormData,
  DetailsResponse,
  LinkedInOAuthResponse,
  VerifyLinkedInOAuthQueryParams,
} from 'api/verification/types';
import { createActionFactories } from 'shared/helpers/actions';

const { createAsync } = createActionFactories('_resources/verification');

export const verifyLinkedInOAuth = createAsync<
  VerifyLinkedInOAuthQueryParams,
  LinkedInOAuthResponse
>('VERIFY_LINKEDIN_OAUTH', async (params) => {
  return await verification.verifyLinkedInOAuth(params);
});

export const postDetails = createAsync<DetailsFormData, DetailsResponse>(
  'POST_DETAILS',
  async (params) => {
    return await verification.postDetails(params);
  },
);
