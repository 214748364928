import * as onboarding from 'api/onboarding';
import { OnboardingFormData, OnboardingResponse } from 'api/onboarding/types';
import { createActionFactories } from 'shared/helpers/actions';

const { createAsync } = createActionFactories('_resources/onboarding');

export const postOnboarding = createAsync<OnboardingFormData, OnboardingResponse>(
  'POST_ONBOARDING',
  async (params) => {
    return await onboarding.postOnboarding(params);
  },
);
