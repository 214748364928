import { RecordOf } from 'immutable';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';

import { getInitialState } from 'shared/helpers/reducer';
import * as storeSurveyActions from 'store/survey/actions';

import { SurveyRouteConfigState } from './types';

const defaultValues: SurveyRouteConfigState = {
  shouldShowTopLevelLoadingSpinner: false,
};

export const initialState = getInitialState<SurveyRouteConfigState>(defaultValues);

export default (
  state: RecordOf<SurveyRouteConfigState> = initialState,
  action: Action,
): RecordOf<SurveyRouteConfigState> => {
  if (isType(action, storeSurveyActions.getContent.async.started)) {
    return state.set('shouldShowTopLevelLoadingSpinner', true);
  }

  if (isType(action, storeSurveyActions.getContent.async.done)) {
    return state.set('shouldShowTopLevelLoadingSpinner', false);
  }

  if (isType(action, storeSurveyActions.getContent.async.failed)) {
    return state.set('shouldShowTopLevelLoadingSpinner', false);
  }

  return state;
};
