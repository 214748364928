import { RecordOf } from 'immutable';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';

import { PaymentResponse } from 'api/payment/types';
import { getInitialState } from 'shared/helpers/reducer';
import { paymentInitializer } from 'api/payment/helpers';

import * as actions from './actions';
import { PaymentState } from './types';
import { AxiosError } from 'axios';

const defaultValues: PaymentState = {
  paymentResult: null,
  errorCount: 0,
};

export const initialState = getInitialState<PaymentState>(defaultValues);

export default (
  state: RecordOf<PaymentState> = initialState,
  action: Action,
): RecordOf<PaymentState> => {
  if (isType(action, actions.getPayment.async.done)) {
    const response: PaymentResponse = action.payload.result;
    return state.set('paymentResult', paymentInitializer(response));
  }

  if (isType(action, actions.getPayment.async.failed)) {
    const error = action.payload.error as AxiosError;
    if (error?.response?.status === 503) {
      return state.set('errorCount', state.errorCount + 1);
    }
  }

  if (isType(action, actions.resetErrorCount)) {
    return state.set('errorCount', 0);
  }

  return state;
};
