import { ComponentType } from 'react';

import EntryHome from 'uicontainers/apps/survey/entry/Home';
import FraudUI from 'uicontainers/apps/survey/Fraud';
import OnboardingHome from 'uicontainers/apps/survey/onboarding/Home';
import QuotaUI from 'uicontainers/apps/survey/Quota';
import ScreenedUI from 'uicontainers/apps/survey/Screened';
import CustomBlockUI from 'uicontainers/apps/survey/CustomBlock';
import SurveyCompletedUI from 'uicontainers/apps/survey/SurveyCompleted';
import VerificationHome from 'uicontainers/apps/survey/verification/Home';

import { Block } from 'api/surveys/types';

export const BLOCK_TYPE_TO_COMPONENT_MAP: { [key in Block]: ComponentType } = {
  COMPLETED: SurveyCompletedUI,
  CUSTOM: CustomBlockUI,
  FRAUD: FraudUI,
  ONBOARDING: OnboardingHome,
  QUOTA: QuotaUI,
  SCREENED: ScreenedUI,
  VERIFICATION: VerificationHome,
  ENTRY: EntryHome,
};
