import React from 'react';

import surveyClosed from 'assets/images/surveyClosed.svg';
import BlockContainer from 'uicontainers/_shared/container/BlockContainer';
import { Props } from 'uicontainers/_shared/container/BlockContainer/types';

const SurveyClosed = () => {
  const containerProps: Props = {
    header: 'This survey is now closed!',
    subHeaders: [
      {
        content: `Looks like you missed this one. But no worries, we have new surveys all the time. Look out
        for additional emails from us going forward!`,
      },
      {
        content: `If you see any problems, please email payments@newtonx.com. We're here to help!`,
      },
    ],
    image: {
      path: surveyClosed,
      altText: 'Survey closed',
    },
  };

  return <BlockContainer {...containerProps}></BlockContainer>;
};

export default SurveyClosed;
