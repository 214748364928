import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';

import { RootState } from 'rootReducer';

export const createActionFactories = (prefix: string) => {
  const create = actionCreatorFactory(prefix);
  const createAsync = asyncFactory<RootState>(create);

  return { create, createAsync };
};
