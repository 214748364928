import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'shared/routes/constants';
import ErrorUI from 'uicontainers/apps/survey/Error';

export const Error = () => {
  return (
    <>
      <Switch>
        <Route path={ROUTES.SURVEY.PATHS.ERROR} component={ErrorUI} />
      </Switch>
    </>
  );
};

export default Error;
