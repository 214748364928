import React from 'react';

import verificationAcknowledgement from 'assets/images/verificationAcknowledgement.svg';
import BlockContainer from 'uicontainers/_shared/container/BlockContainer';
import { Props as ContainerProps } from 'uicontainers/_shared/container/BlockContainer/types';

export const DoNotQualify = () => {
  const containerProps: ContainerProps = {
    header: 'Thanks for letting us know!',
    subHeaders: [
      {
        content: `Sorry it isn't the right survey for you, but we have new surveys starting all the time so hopefully we will see you again soon`,
      },
    ],
    image: {
      path: verificationAcknowledgement,
      altText: 'Verification Acknowledgement',
    },
  };

  return <BlockContainer {...containerProps}></BlockContainer>;
};

export default DoNotQualify;
