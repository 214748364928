import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import { useActions } from 'shared/hooks';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { surveyFeatureFlagsSelector } from './selectors';
import { setFeatureFlag } from './actions';
import { surveyFeatureFlagsState } from './types';

// @ts-ignore
export const useSurveyFeatureFlagsState = (): surveyFeatureFlagsState & PersistPartial =>
  // @ts-ignore
  useSelector(surveyFeatureFlagsSelector);
export const useFeatureFlagValue = (flagName: keyof surveyFeatureFlagsState['flags']) =>
  // @ts-ignore
  useSelector(surveyFeatureFlagsSelector).flags[flagName];

export const useFeatureFlagActions = () =>
  useActions({
    setFeatureFlag: setFeatureFlag,
  });

export const useOnFeatureFlagChange = () => {
  const { setFeatureFlag } = useFeatureFlagActions();

  return useCallback(
    (flagName: string) => (e: CheckboxChangeEvent) => {
      setFeatureFlag({ name: flagName, value: e.target.checked });
    },
    [setFeatureFlag],
  );
};
