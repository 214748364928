import { RecordOf } from 'immutable';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';

import { linkedInOAuthInitializer } from 'api/verification/helpers';
import { LinkedInOAuthResponse } from 'api/verification/types';
import { getInitialState } from 'shared/helpers/reducer';

import * as actions from './actions';
import { VerificationState } from './types';

const defaultValues: VerificationState = {
  linkedInOAuthResult: null,
};

export const initialState = getInitialState<VerificationState>(defaultValues);

export default (
  state: RecordOf<VerificationState> = initialState,
  action: Action,
): RecordOf<VerificationState> => {
  if (isType(action, actions.verifyLinkedInOAuth.async.done)) {
    const response: LinkedInOAuthResponse = action.payload.result;
    return state.set('linkedInOAuthResult', linkedInOAuthInitializer(response));
  }

  return state;
};
