import { Record, RecordOf } from 'immutable';

import { LinkedInOAuth } from 'api/verification/types';

export const linkedInOAuthInitializer = (linkedInOAuth: LinkedInOAuth): RecordOf<LinkedInOAuth> => {
  const defaultValues: LinkedInOAuth = {
    is_success: false,
    redirect_url: '',
    data: {},
  };
  return Record<LinkedInOAuth>(defaultValues)(linkedInOAuth);
};
