import { useForm } from 'antd/lib/form/Form';
import { countries } from 'countries-list';
import sortBy from 'lodash/sortBy';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DetailsFormData } from 'api/verification/types';
import { Alert, FormItem, Input, Select, SelectOption } from 'components/misc/helpers';
import NXButton from 'components/misc/NXButton';
import NXForm from 'components/misc/NXForm';
import BlockContainer from 'uicontainers/_shared/container/BlockContainer';
import { Props as ContainerProps } from 'uicontainers/_shared/container/BlockContainer/types';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';

import { EMAIL_RULES, formItemLayout, REQUIRED_RULE, WEBSITE_RULES } from './constants';
import styles from './details.module.scss';
import { onSelectFocus, onSelectOptionFilter, redirectToUrl } from './helpers';
import {
  useHandleSubmit,
  useOnAlertClose,
  useOnDomainChange,
  useVerificationSelectors,
} from './hooks';
import { ParamTypes, Props } from './types';

export const Details: FC<Props> = () => {
  const [form] = useForm();
  const [isValidEmail, setIsValidEmail] = useState(true);

  const containerProps: ContainerProps = {
    header: 'A few more details',
    subHeaders: [
      {
        content: `To ensure that this is the best survey for you, we need more information. We will email you if you are a good fit!`,
      },
    ],
  };

  const handleSubmit = useHandleSubmit();
  const onSubmit = (formData: DetailsFormData) => handleSubmit(formData, setIsValidEmail);

  const handleOnDomainChange = useOnDomainChange(setIsValidEmail);
  const { redirect_to, manualVerificationError } = useVerificationSelectors();
  const onDomainChange = () => handleOnDomainChange(form.getFieldsValue());

  const handleOnAlertClose = useOnAlertClose(setIsValidEmail);

  const countryList = sortBy(countries, 'name');

  // TODO: before removing it wait until api completion done
  const { uuid } = useParams<ParamTypes>();

  const nonFieldError = manualVerificationError?.non_field_errors ?? null;
  const firstNameError = manualVerificationError?.first_name ?? null;
  const lastNameError = manualVerificationError?.last_name ?? null;
  const webSiteError = manualVerificationError?.website ?? null;
  const emailAddressError = manualVerificationError?.email_address ?? null;

  React.useEffect(() => {
    redirectToUrl(redirect_to as string);
  }, [redirect_to]);

  return (
    <VerticalSpaceContainer>
      <BlockContainer {...containerProps}></BlockContainer>

      {nonFieldError && <Alert message={nonFieldError[0]} type="error" showIcon />}
      {emailAddressError && <Alert message={emailAddressError[0]} type="error" showIcon />}
      {firstNameError && <Alert message={firstNameError[0]} type="error" showIcon />}
      {lastNameError && <Alert message={lastNameError[0]} type="error" showIcon />}
      {webSiteError && <Alert message={webSiteError[0]} type="error" showIcon />}

      <div className={styles.formContainer}>
        <NXForm
          initialValues={{
            seid: uuid,
          }}
          layout="vertical"
          {...formItemLayout}
          form={form}
          autoComplete="off"
          colon={true}
          requiredMark={true}
          onFinish={onSubmit}
        >
          <FormItem name={'first_name'} label="First Name" rules={REQUIRED_RULE('First Name')}>
            <Input placeholder="Please input" autoComplete="new-password" />
          </FormItem>

          <FormItem hidden={true} name={'seid'}></FormItem>

          <FormItem name={'last_name'} label="Last Name" rules={REQUIRED_RULE('Last Name')}>
            <Input placeholder="Please input" autoComplete="new-password" />
          </FormItem>

          <FormItem name={'website'} label="Company Website" rules={WEBSITE_RULES}>
            <Input onChange={onDomainChange} placeholder="http(s)://<company domain>" />
          </FormItem>

          <FormItem name={'email_address'} label="Work Email" rules={EMAIL_RULES}>
            <Input onChange={onDomainChange} placeholder="Please input" />
          </FormItem>

          <FormItem name={'job_role'} label="Job Title" rules={REQUIRED_RULE('Job Title')}>
            <Input placeholder="Please input" />
          </FormItem>

          <FormItem name={'location'} label="Location" rules={REQUIRED_RULE('Location')}>
            <Select
              placeholder="Please select"
              showSearch
              onFocus={onSelectFocus}
              optionFilterProp="children"
              filterOption={onSelectOptionFilter}
            >
              {Object.values(countryList).map((country) => (
                <SelectOption key={country.name} value={country.name}>
                  {country.name}
                </SelectOption>
              ))}
            </Select>
          </FormItem>

          <FormItem>
            <NXButton type="primary" htmlType="submit">
              Apply to take survey
            </NXButton>
          </FormItem>
        </NXForm>

        {!isValidEmail && (
          <Alert
            message="Work email should match Company Website domain"
            type="warning"
            showIcon
            closable
            onClose={handleOnAlertClose}
          />
        )}
      </div>
    </VerticalSpaceContainer>
  );
};

export default Details;
