import React from 'react';

import { Content as AntdContent } from 'components/misc/helpers';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';

import styles from './content.module.scss';

export const Content = (props: React.PropsWithChildren<object>) => {
  const { children } = props;
  return (
    <AntdContent className={styles.container}>
      <div className={styles.children}>
        <VerticalSpaceContainer>{children}</VerticalSpaceContainer>
      </div>
    </AntdContent>
  );
};

export default Content;
