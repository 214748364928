import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { createMigrate } from 'redux-persist';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import * as actions from './actions';
import { initialSurveyFeatureFlagsState, migrations } from './helpers';
import { surveyFeatureFlagsState } from './types';

export const initialState = initialSurveyFeatureFlagsState;

export const surveyFeatureFlagsReducer = (
  state = initialState,
  action: Action,
): surveyFeatureFlagsState => {
  if (isType(action, actions.setFeatureFlag)) {
    return {
      ...state,
      flags: {
        ...state.flags,
        [action.payload.name]: action.payload.value,
      },
    };
  }
  return state;
};

export default persistReducer(
  {
    key: 'flags',
    storage,
    stateReconciler: autoMergeLevel2,
    version: Number(process.env['REACT_APP_FLAGS_VERSION']) || 0,
    migrate: createMigrate(migrations),
  },
  // @ts-ignore
  surveyFeatureFlagsReducer,
);
