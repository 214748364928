import Layout from 'antd/lib/layout';
import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { Route, Switch } from 'react-router-dom';

import Error from 'pages/apps/survey/Error';
import LinkedInPostAuth from 'pages/apps/survey/LinkedInPostAuth';
import Payment from 'pages/apps/survey/PaymentPending';
import PaymentError from 'pages/apps/survey/PaymentError';
import PaymentClaim from 'pages/apps/survey/PaymentClaim';
import Flags from 'pages/apps/survey/Flags';
import SurveyClosed from 'pages/apps/survey/SurveyClosed';
import VerificationAcknowledgement from 'pages/apps/survey/VerificationAcknowledgement';
import { BLOCK_PAGES, NON_BLOCK_PAGES, ROUTES } from 'shared/routes/constants';
import Content from 'uicontainers/_shared/layout/content/Content';
import ErrorUI from 'uicontainers/apps/survey/Error';
import ReferralsClosed from 'uicontainers/apps/survey/ReferralsClosed';
import TopLevelLoadingSpinner from 'uicontainers/apps/survey/_shared/TopLevelLoadingSpinner';

import { BLOCK_TYPE_TO_COMPONENT_MAP } from './constants';
import { useAuthorize, useSurveyRouteConfigSelectors } from './hooks';
import Terms from '../../_shared/Terms';

export const OpenRoutes = () => {
  return (
    <Layout>
      <Content>
        <Switch>
          <Route path={ROUTES.SURVEY.PATHS.PAYMENT_PENDING} component={Payment} />
          <Route path={ROUTES.SURVEY.PATHS.PAYMENT_ERROR} component={PaymentError} />
          <Route path={ROUTES.SURVEY.PATHS.PAYMENT_CLAIM_BY_ID} component={PaymentClaim} />
          <Route
            path={ROUTES.SURVEY.PATHS.VERIFICATION_ACKNOWLEDGEMENT}
            component={VerificationAcknowledgement}
          />
          <Route path={ROUTES.SURVEY.PATHS.LINKEDIN_POSTAUTH} component={LinkedInPostAuth} />
          <Route path={ROUTES.SURVEY.PATHS.SURVEY_CLOSED} component={SurveyClosed} />
          <Route path={ROUTES.SURVEY.PATHS.ERROR} component={Error} />
          <Route path={ROUTES.SURVEY.PATHS.TERMS} component={Terms} />
          <Route path={ROUTES.SURVEY.PATHS.FLAGS} component={Flags} />
          <Route exact path={ROUTES.SURVEY.PATHS.LINKEDIN_POPUP} component={LinkedInPopUp} />
          <Route path={ROUTES.SURVEY.PATHS.REFERRALS_CLOSED} component={ReferralsClosed} />
        </Switch>
      </Content>
    </Layout>
  );
};

export const AuthorizedRoutes = () => {
  const {
    isAuthorized,
    isAuthorizing,
    redirectTo,
    blockType,
    content,
  } = useSurveyRouteConfigSelectors();

  useAuthorize();

  const Component = blockType && BLOCK_TYPE_TO_COMPONENT_MAP[blockType];

  const shouldRenderContent = Boolean(!isAuthorizing && isAuthorized && !redirectTo && Component);
  const shouldRenderErrorPage = !isAuthorizing && !isAuthorized && !redirectTo;
  const heading = content?.heading ?? 'Survey';
  const subHeading =
    content?.subheading ?? 'Click on the link to continue through the survey process';

  return (
    <Layout>
      <Helmet>
        <title>{heading}</title>
        <meta name="description" content={subHeading} />
      </Helmet>
      <Content>
        {/*
         // @ts-ignore */}
        {shouldRenderContent && Component && <Component />}

        {shouldRenderErrorPage && <ErrorUI />}
        {shouldRenderErrorPage && <Flags />}
      </Content>
    </Layout>
  );
};

const SurveyRouteConfig = () => {
  const { shouldShowTopLevelLoadingSpinner } = useSurveyRouteConfigSelectors();

  return (
    <div>
      {shouldShowTopLevelLoadingSpinner ? <TopLevelLoadingSpinner /> : null}
      <Switch>
        <Route path={NON_BLOCK_PAGES} component={OpenRoutes} />
        <Route path={BLOCK_PAGES} component={AuthorizedRoutes} />
      </Switch>
    </div>
  );
};

export default SurveyRouteConfig;
