import { useSelector } from 'react-redux';

import { RootState } from 'rootReducer';
import { useActions } from 'shared/hooks';
import { verifyLinkedInOAuth, postDetails } from 'store/verification/actions';

import { selectIsPostingDetails, selectIsVerifyingLinkedInOAuth } from './selectors';

export const useVerificationActions = () =>
  useActions({
    verifyLinkedInOAuth,
    postDetails,
  });

export const useVerificationSelectors = () =>
  useSelector((state: RootState) => {
    return {
      isVerifyingLinkedInOAuth: selectIsVerifyingLinkedInOAuth(state),
      isPostingDetails: selectIsPostingDetails(state),
    };
  });
