import React from 'react';

import Handlebars from 'uicontainers/apps/survey/_shared/handlebars/Handlebars';
import Heading from 'uicontainers/_shared/Heading';
import HtmlRenderer from 'components/misc/HtmlRenderer';

import { removeAllHandlebars } from 'shared/helpers/handlebar';
import { useSurveyContent } from 'shared/hooks';

const SurveyCompleted = () => {
  const { surveyContent } = useSurveyContent();
  const heading = removeAllHandlebars(surveyContent?.content?.heading ?? null);
  const subheading = removeAllHandlebars(surveyContent?.content?.subheading ?? null) ?? '';

  return (
    <>
      {heading && <Heading text={heading} />}
      <HtmlRenderer content={subheading} />
      <Handlebars />
    </>
  );
};

export default SurveyCompleted;
