import { linkedInUrl } from './constants';

export const validateMessages = {
  types: {
    email: 'Please insert valid email!',
  },
};

export const redirectToUrl = (redirect_to: string) => {
  redirect_to && window.location.assign(redirect_to);
};

export const linkedInPopUp = () => {
  const width = 460,
    height = 400,
    left = window.screenLeft + 200,
    top = window.screenTop + 200;
  const aSizeAndPositions = `width=${width},height=${height},left=${left},top=${top}`;
  window.open(linkedInUrl, 'popup', aSizeAndPositions);
};

//@ts-ignore
export const linkedinURLValidator = (rule, value) => {
  if (value === null || value === undefined || value === '') {
    return Promise.resolve();
  }

  if (/^((https:\/\/)?((www|uk)\.)?)?linkedin.com\/in.*$/.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject(rule.message);
};
