import camelCase from 'lodash/camelCase';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import { surveyFeatureFlagsState } from './types';

export const initialSurveyFeatureFlagsState: surveyFeatureFlagsState = {
  flags: fromPairs(
    toPairs(process.env)
      .filter(([key]) => key.includes('_FEATURE_FLAG_'))
      .map(([key, value]) => [
        camelCase(key.replace('REACT_APP_FEATURE_FLAG', '')),
        value === 'true',
      ]),
  ),
  enabled: process.env['REACT_APP_FLAGS_UI_ENABLED'] === 'false',
};

export const migrations = {
  [Number(process.env['REACT_APP_FLAGS_VERSION']) || 0]: (state: any) => { // eslint-disable-line
    const updatedFlags = fromPairs(
      toPairs(state.flags).filter(([key]) =>
        initialSurveyFeatureFlagsState.flags.hasOwnProperty(key),
      ),
    );

    return {
      ...state,
      flags: updatedFlags,
    };
  },
};
