import { DetailsFormData } from 'api/verification/types';
import { getDomainNameFromEmail, getDomainNameFromUrl } from 'shared/helpers/misc';

export const isValidWorkEmail = (detailsFormData: DetailsFormData) => {
  const companyDomain = getDomainNameFromUrl(detailsFormData.website);
  const emailDomain = getDomainNameFromEmail(detailsFormData.email_address);

  if (companyDomain && emailDomain) {
    return companyDomain === emailDomain;
  }

  return true;
};

export const redirectToUrl = (redirect_to: string) => {
  redirect_to && window.location.assign(redirect_to);
};

export const onSelectFocus = (event: React.FocusEvent<HTMLElement>) => {
  event.target.setAttribute('autocomplete', 'new-password');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onSelectOptionFilter = (input: string, option: any) =>
  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
