import axios, { AxiosRequestConfig } from 'axios';

import { CURRENT_DOMAIN } from './constants';
import { InterceptErrorResponse, InterceptSuccessResponse } from './types';

export const DEFAULT_API_TIMEOUT = 60000;

export const replaceURLDomainToBrowserDomain = (apiEndPoint: string) => {
  if (!apiEndPoint) {
    apiEndPoint = CURRENT_DOMAIN;
  }
  let browserDomain = window.location.host;
  if (process.env.REACT_APP_ENV === 'local') {
    browserDomain = process.env.REACT_APP_API_TEST_DOMAIN || '';
  }
  return apiEndPoint.replace(CURRENT_DOMAIN, browserDomain);
};

export const axiosCreate = (params: AxiosRequestConfig = {}) => {
  const { baseURL } = params;

  return (successResponse: InterceptSuccessResponse, errorResponse: InterceptErrorResponse) => {
    const instance = axios.create({
      baseURL,
      timeout: DEFAULT_API_TIMEOUT,
      withCredentials: false,
    });

    instance.interceptors.response.use(successResponse, errorResponse);

    return instance;
  };
};
