import { RemoveHandlebars, HasHandlebar } from './types';

export const removeAllHandlebars: RemoveHandlebars = (html) => {
  // eslint-disable-next-line no-useless-escape
  return html?.replace(/\[[^\[\]]+\]/g, '') ?? null;
};

export const hasHandlebar: HasHandlebar = (html, handlebar) => {
  return html ? html.search(handlebar) > -1 : false;
};
