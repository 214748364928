import { Country } from '../PhoneNumberFormItem/types';

// Adopted from https://www.npmjs.com/package/country-codes-list
export const countries: Country[] = [
  {
    id: '0',
    countryNameEn: 'Andorra',
    countryCallingCode: '376',
    flag: '🇦🇩',
  },
  {
    id: '1',
    countryNameEn: 'Afghanistan',
    countryCallingCode: '93',
    flag: '🇦🇫',
  },
  {
    id: '2',
    countryNameEn: 'Antigua and Barbuda',
    countryCallingCode: '1268',
    flag: '🇦🇬',
  },
  {
    id: '3',
    countryNameEn: 'Anguilla',
    countryCallingCode: '1264',
    flag: '🇦🇮',
  },
  {
    id: '4',
    countryNameEn: 'Albania',
    countryCallingCode: '355',
    flag: '🇦🇱',
  },
  {
    id: '5',
    countryNameEn: 'Armenia',
    countryCallingCode: '374',
    flag: '🇦🇲',
  },
  {
    id: '6',
    countryNameEn: 'Angola',
    countryCallingCode: '244',
    flag: '🇦🇴',
  },
  {
    id: '7',
    countryNameEn: 'Antarctica',
    countryCallingCode: '672',
    flag: '🇦🇶',
  },
  {
    id: '8',
    countryNameEn: 'Argentina',
    countryCallingCode: '54',
    flag: '🇦🇷',
  },
  {
    id: '9',
    countryNameEn: 'American Samoa',
    countryCallingCode: '1684',
    flag: '🇦🇸',
  },
  {
    id: '10',
    countryNameEn: 'Austria',
    countryCallingCode: '43',
    flag: '🇦🇹',
  },
  {
    id: '11',
    countryNameEn: 'Australia',
    countryCallingCode: '61',
    flag: '🇦🇺',
  },
  {
    id: '12',
    countryNameEn: 'Aruba',
    countryCallingCode: '297',
    flag: '🇦🇼',
  },
  {
    id: '13',
    countryNameEn: 'Åland Islands',
    countryCallingCode: '358',
    flag: '🇦🇽',
  },
  {
    id: '14',
    countryNameEn: 'Azerbaijan',
    countryCallingCode: '994',
    flag: '🇦🇿',
  },
  {
    id: '15',
    countryNameEn: 'Bosnia and Herzegovina',
    countryCallingCode: '387',
    flag: '🇧🇦',
  },
  {
    id: '16',
    countryNameEn: 'Barbados',
    countryCallingCode: '1246',
    flag: '🇧🇧',
  },
  {
    id: '17',
    countryNameEn: 'Bangladesh',
    countryCallingCode: '880',
    flag: '🇧🇩',
  },
  {
    id: '18',
    countryNameEn: 'Belgium',
    countryCallingCode: '32',
    flag: '🇧🇪',
  },
  {
    id: '19',
    countryNameEn: 'Burkina Faso',
    countryCallingCode: '226',
    flag: '🇧🇫',
  },
  {
    id: '20',
    countryNameEn: 'Bulgaria',
    countryCallingCode: '359',
    flag: '🇧🇬',
  },
  {
    id: '21',
    countryNameEn: 'Bahrain',
    countryCallingCode: '973',
    flag: '🇧🇭',
  },
  {
    id: '22',
    countryNameEn: 'Burundi',
    countryCallingCode: '257',
    flag: '🇧🇮',
  },
  {
    id: '23',
    countryNameEn: 'Benin',
    countryCallingCode: '229',
    flag: '🇧🇯',
  },
  {
    id: '24',
    countryNameEn: 'Saint Barthélemy',
    countryCallingCode: '590',
    flag: '🇧🇱',
  },
  {
    id: '25',
    countryNameEn: 'Bermuda',
    countryCallingCode: '1441',
    flag: '🇧🇲',
  },
  {
    id: '26',
    countryNameEn: 'Brunei Darussalam',
    countryCallingCode: '673',
    flag: '🇧🇳',
  },
  {
    id: '27',
    countryNameEn: 'Bolivia (Plurinational State of)',
    countryCallingCode: '591',
    flag: '🇧🇴',
  },
  {
    id: '28',
    countryNameEn: 'Bonaire, Sint Eustatius and Saba',
    countryCallingCode: '5997',
    flag: '🇧🇶',
  },
  {
    id: '29',
    countryNameEn: 'Brazil',
    countryCallingCode: '55',
    flag: '🇧🇷',
  },
  {
    id: '30',
    countryNameEn: 'Bhutan',
    countryCallingCode: '975',
    flag: '🇧🇹',
  },
  {
    id: '31',
    countryNameEn: 'Bouvet Island',
    countryCallingCode: '47',
    flag: '🇧🇻',
  },
  {
    id: '32',
    countryNameEn: 'Botswana',
    countryCallingCode: '267',
    flag: '🇧🇼',
  },
  {
    id: '33',
    countryNameEn: 'Belarus',
    countryCallingCode: '375',
    flag: '🇧🇾',
  },
  {
    id: '34',
    countryNameEn: 'Belize',
    countryCallingCode: '501',
    flag: '🇧🇿',
  },
  {
    id: '35',
    countryNameEn: 'Canada',
    countryCallingCode: '1',
    flag: '🇨🇦',
  },
  {
    id: '36',
    countryNameEn: 'Switzerland',
    countryCallingCode: '41',
    flag: '🇨🇭',
  },
  {
    id: '37',
    countryNameEn: "Côte d'Ivoire",
    countryCallingCode: '225',
    flag: '🇨🇮',
  },
  {
    id: '38',
    countryNameEn: 'Chile',
    countryCallingCode: '56',
    flag: '🇨🇱',
  },
  {
    id: '39',
    countryNameEn: 'Cameroon',
    countryCallingCode: '237',
    flag: '🇨🇲',
  },
  {
    id: '40',
    countryNameEn: 'China',
    countryCallingCode: '86',
    flag: '🇨🇳',
  },
  {
    id: '41',
    countryNameEn: 'Colombia',
    countryCallingCode: '57',
    flag: '🇨🇴',
  },
  {
    id: '42',
    countryNameEn: 'Costa Rica',
    countryCallingCode: '506',
    flag: '🇨🇷',
  },
  {
    id: '43',
    countryNameEn: 'Cuba',
    countryCallingCode: '53',
    flag: '🇨🇺',
  },
  {
    id: '44',
    countryNameEn: 'Cabo Verde',
    countryCallingCode: '238',
    flag: '🇨🇻',
  },
  {
    id: '45',
    countryNameEn: 'Curaçao',
    countryCallingCode: '599',
    flag: '🇨🇼',
  },
  {
    id: '46',
    countryNameEn: 'Christmas Island',
    countryCallingCode: '61',
    flag: '🇨🇽',
  },
  {
    id: '47',
    countryNameEn: 'Cyprus',
    countryCallingCode: '357',
    flag: '🇨🇾',
  },
  {
    id: '48',
    countryNameEn: 'Germany',
    countryCallingCode: '49',
    flag: '🇩🇪',
  },
  {
    id: '49',
    countryNameEn: 'Djibouti',
    countryCallingCode: '253',
    flag: '🇩🇯',
  },
  {
    id: '50',
    countryNameEn: 'Denmark',
    countryCallingCode: '45',
    flag: '🇩🇰',
  },
  {
    id: '51',
    countryNameEn: 'Dominica',
    countryCallingCode: '767',
    flag: '🇩🇲',
  },
  {
    id: '52',
    countryNameEn: 'Algeria',
    countryCallingCode: '213',
    flag: '🇩🇿',
  },
  {
    id: '53',
    countryNameEn: 'Ecuador',
    countryCallingCode: '593',
    flag: '🇪🇨',
  },
  {
    id: '54',
    countryNameEn: 'Estonia',
    countryCallingCode: '372',
    flag: '🇪🇪',
  },
  {
    id: '55',
    countryNameEn: 'Egypt',
    countryCallingCode: '20',
    flag: '🇪🇬',
  },
  {
    id: '56',
    countryNameEn: 'Western Sahara',
    countryCallingCode: '212',
    flag: '🇪🇭',
  },
  {
    id: '57',
    countryNameEn: 'Eritrea',
    countryCallingCode: '291',
    flag: '🇪🇷',
  },
  {
    id: '58',
    countryNameEn: 'Spain',
    countryCallingCode: '34',
    flag: '🇪🇸',
  },
  {
    id: '59',
    countryNameEn: 'Ethiopia',
    countryCallingCode: '251',
    flag: '🇪🇹',
  },
  {
    id: '60',
    countryNameEn: 'Finland',
    countryCallingCode: '358',
    flag: '🇫🇮',
  },
  {
    id: '61',
    countryNameEn: 'Fiji',
    countryCallingCode: '679',
    flag: '🇫🇯',
  },
  {
    id: '62',
    countryNameEn: 'Micronesia (Federated States of)',
    countryCallingCode: '691',
    flag: '🇫🇲',
  },
  {
    id: '63',
    countryNameEn: 'France',
    countryCallingCode: '33',
    flag: '🇫🇷',
  },
  {
    id: '64',
    countryNameEn: 'Gabon',
    countryCallingCode: '241',
    flag: '🇬🇦',
  },
  {
    id: '65',
    countryNameEn: 'Grenada',
    countryCallingCode: '1473',
    flag: '🇬🇩',
  },
  {
    id: '66',
    countryNameEn: 'Georgia',
    countryCallingCode: '995',
    flag: '🇬🇪',
  },
  {
    id: '67',
    countryNameEn: 'French Guiana',
    countryCallingCode: '594',
    flag: '🇬🇫',
  },
  {
    id: '68',
    countryNameEn: 'Guernsey',
    countryCallingCode: '44',
    flag: '🇬🇬',
  },
  {
    id: '69',
    countryNameEn: 'Ghana',
    countryCallingCode: '233',
    flag: '🇬🇭',
  },
  {
    id: '70',
    countryNameEn: 'Gibraltar',
    countryCallingCode: '350',
    flag: '🇬🇮',
  },
  {
    id: '71',
    countryNameEn: 'Greenland',
    countryCallingCode: '299',
    flag: '🇬🇱',
  },
  {
    id: '72',
    countryNameEn: 'Guinea',
    countryCallingCode: '224',
    flag: '🇬🇳',
  },
  {
    id: '73',
    countryNameEn: 'Guadeloupe',
    countryCallingCode: '590',
    flag: '🇬🇵',
  },
  {
    id: '74',
    countryNameEn: 'Equatorial Guinea',
    countryCallingCode: '240',
    flag: '🇬🇶',
  },
  {
    id: '75',
    countryNameEn: 'Greece',
    countryCallingCode: '30',
    flag: '🇬🇷',
  },
  {
    id: '76',
    countryNameEn: 'South Georgia and the South Sandwich Islands',
    countryCallingCode: '500',
    flag: '🇬🇸',
  },
  {
    id: '77',
    countryNameEn: 'Guatemala',
    countryCallingCode: '502',
    flag: '🇬🇹',
  },
  {
    id: '78',
    countryNameEn: 'Guam',
    countryCallingCode: '1',
    flag: '🇬🇺',
  },
  {
    id: '79',
    countryNameEn: 'Guinea-Bissau',
    countryCallingCode: '245',
    flag: '🇬🇼',
  },
  {
    id: '80',
    countryNameEn: 'Guyana',
    countryCallingCode: '592',
    flag: '🇬🇾',
  },
  {
    id: '81',
    countryNameEn: 'Hong Kong',
    countryCallingCode: '852',
    flag: '🇭🇰',
  },
  {
    id: '82',
    countryNameEn: 'Honduras',
    countryCallingCode: '504',
    flag: '🇭🇳',
  },
  {
    id: '83',
    countryNameEn: 'Croatia',
    countryCallingCode: '385',
    flag: '🇭🇷',
  },
  {
    id: '84',
    countryNameEn: 'Haiti',
    countryCallingCode: '509',
    flag: '🇭🇹',
  },
  {
    id: '85',
    countryNameEn: 'Hungary',
    countryCallingCode: '36',
    flag: '🇭🇺',
  },
  {
    id: '86',
    countryNameEn: 'Indonesia',
    countryCallingCode: '62',
    flag: '🇮🇩',
  },
  {
    id: '87',
    countryNameEn: 'Ireland',
    countryCallingCode: '353',
    flag: '🇮🇪',
  },
  {
    id: '88',
    countryNameEn: 'Israel',
    countryCallingCode: '972',
    flag: '🇮🇱',
  },
  {
    id: '89',
    countryNameEn: 'Isle of Man',
    countryCallingCode: '44',
    flag: '🇮🇲',
  },
  {
    id: '90',
    countryNameEn: 'India',
    countryCallingCode: '91',
    flag: '🇮🇳',
  },
  {
    id: '91',
    countryNameEn: 'Iraq',
    countryCallingCode: '964',
    flag: '🇮🇶',
  },
  {
    id: '92',
    countryNameEn: 'Iran (Islamic Republic of)',
    countryCallingCode: '98',
    flag: '🇮🇷',
  },
  {
    id: '93',
    countryNameEn: 'Iceland',
    countryCallingCode: '354',
    flag: '🇮🇸',
  },
  {
    id: '94',
    countryNameEn: 'Italy',
    countryCallingCode: '39',
    flag: '🇮🇹',
  },
  {
    id: '95',
    countryNameEn: 'Jersey',
    countryCallingCode: '44',
    flag: '🇯🇪',
  },
  {
    id: '96',
    countryNameEn: 'Jamaica',
    countryCallingCode: '876',
    flag: '🇯🇲',
  },
  {
    id: '97',
    countryNameEn: 'Jordan',
    countryCallingCode: '962',
    flag: '🇯🇴',
  },
  {
    id: '98',
    countryNameEn: 'Japan',
    countryCallingCode: '81',
    flag: '🇯🇵',
  },
  {
    id: '99',
    countryNameEn: 'Kenya',
    countryCallingCode: '254',
    flag: '🇰🇪',
  },
  {
    id: '100',
    countryNameEn: 'Kyrgyzstan',
    countryCallingCode: '996',
    flag: '🇰🇬',
  },
  {
    id: '101',
    countryNameEn: 'Cambodia',
    countryCallingCode: '855',
    flag: '🇰🇭',
  },
  {
    id: '102',
    countryNameEn: 'North Korea',
    countryCallingCode: '850',
    flag: '🇰🇵',
  },
  {
    id: '103',
    countryNameEn: 'South Korea',
    countryCallingCode: '82',
    flag: '🇰🇷',
  },
  {
    id: '104',
    countryNameEn: 'Kiribati',
    countryCallingCode: '686',
    flag: '🇰🇮',
  },
  {
    id: '105',
    countryNameEn: 'Saint Kitts and Nevis',
    countryCallingCode: '1869',
    flag: '🇰🇳',
  },
  {
    id: '106',
    countryNameEn: 'Kuwait',
    countryCallingCode: '965',
    flag: '🇰🇼',
  },
  {
    id: '107',
    countryNameEn: 'Kazakhstan',
    countryCallingCode: '7',
    flag: '🇰🇿',
  },
  {
    id: '108',
    countryNameEn: 'Lebanon',
    countryCallingCode: '961',
    flag: '🇱🇧',
  },
  {
    id: '109',
    countryNameEn: 'Saint Lucia',
    countryCallingCode: '1758',
    flag: '🇱🇨',
  },
  {
    id: '110',
    countryNameEn: 'Liechtenstein',
    countryCallingCode: '423',
    flag: '🇱🇮',
  },
  {
    id: '111',
    countryNameEn: 'Sri Lanka',
    countryCallingCode: '94',
    flag: '🇱🇰',
  },
  {
    id: '112',
    countryNameEn: 'Liberia',
    countryCallingCode: '231',
    flag: '🇱🇷',
  },
  {
    id: '113',
    countryNameEn: 'Lesotho',
    countryCallingCode: '266',
    flag: '🇱🇸',
  },
  {
    id: '114',
    countryNameEn: 'Lithuania',
    countryCallingCode: '370',
    flag: '🇱🇹',
  },
  {
    id: '115',
    countryNameEn: 'Luxembourg',
    countryCallingCode: '352',
    flag: '🇱🇺',
  },
  {
    id: '116',
    countryNameEn: 'Latvia',
    countryCallingCode: '371',
    flag: '🇱🇻',
  },
  {
    id: '117',
    countryNameEn: 'Libya',
    countryCallingCode: '218',
    flag: '🇱🇾',
  },
  {
    id: '118',
    countryNameEn: 'Morocco',
    countryCallingCode: '212',
    flag: '🇲🇦',
  },
  {
    id: '119',
    countryNameEn: 'Monaco',
    countryCallingCode: '377',
    flag: '🇲🇨',
  },
  {
    id: '120',
    countryNameEn: 'Montenegro',
    countryCallingCode: '382',
    flag: '🇲🇪',
  },
  {
    id: '121',
    countryNameEn: 'Saint Martin (French part)',
    countryCallingCode: '590',
    flag: '🇲🇫',
  },
  {
    id: '122',
    countryNameEn: 'Madagascar',
    countryCallingCode: '261',
    flag: '🇲🇬',
  },
  {
    id: '123',
    countryNameEn: 'Mali',
    countryCallingCode: '223',
    flag: '🇲🇱',
  },
  {
    id: '124',
    countryNameEn: 'Myanmar',
    countryCallingCode: '95',
    flag: '🇲🇲',
  },
  {
    id: '125',
    countryNameEn: 'Mongolia',
    countryCallingCode: '976',
    flag: '🇲🇳',
  },
  {
    id: '126',
    countryNameEn: 'Macao',
    countryCallingCode: '853',
    flag: '🇲🇴',
  },
  {
    id: '127',
    countryNameEn: 'Martinique',
    countryCallingCode: '596',
    flag: '🇲🇶',
  },
  {
    id: '128',
    countryNameEn: 'Mauritania',
    countryCallingCode: '222',
    flag: '🇲🇷',
  },
  {
    id: '129',
    countryNameEn: 'Montserrat',
    countryCallingCode: '1664',
    flag: '🇲🇸',
  },
  {
    id: '130',
    countryNameEn: 'Malta',
    countryCallingCode: '356',
    flag: '🇲🇹',
  },
  {
    id: '131',
    countryNameEn: 'Mauritius',
    countryCallingCode: '230',
    flag: '🇲🇺',
  },
  {
    id: '132',
    countryNameEn: 'Maldives',
    countryCallingCode: '960',
    flag: '🇲🇻',
  },
  {
    id: '133',
    countryNameEn: 'Malawi',
    countryCallingCode: '265',
    flag: '🇲🇼',
  },
  {
    id: '134',
    countryNameEn: 'Mexico',
    countryCallingCode: '52',
    flag: '🇲🇽',
  },
  {
    id: '135',
    countryNameEn: 'Malaysia',
    countryCallingCode: '60',
    flag: '🇲🇾',
  },
  {
    id: '136',
    countryNameEn: 'Mozambique',
    countryCallingCode: '258',
    flag: '🇲🇿',
  },
  {
    id: '137',
    countryNameEn: 'Namibia',
    countryCallingCode: '264',
    flag: '🇳🇦',
  },
  {
    id: '138',
    countryNameEn: 'New Caledonia',
    countryCallingCode: '687',
    flag: '🇳🇨',
  },
  {
    id: '139',
    countryNameEn: 'Norfolk Island',
    countryCallingCode: '672',
    flag: '🇳🇫',
  },
  {
    id: '140',
    countryNameEn: 'Nigeria',
    countryCallingCode: '234',
    flag: '🇳🇬',
  },
  {
    id: '141',
    countryNameEn: 'Nicaragua',
    countryCallingCode: '505',
    flag: '🇳🇮',
  },
  {
    id: '142',
    countryNameEn: 'Norway',
    countryCallingCode: '47',
    flag: '🇳🇴',
  },
  {
    id: '143',
    countryNameEn: 'Nepal',
    countryCallingCode: '977',
    flag: '🇳🇵',
  },
  {
    id: '144',
    countryNameEn: 'Nauru',
    countryCallingCode: '674',
    flag: '🇳🇷',
  },
  {
    id: '145',
    countryNameEn: 'Niue',
    countryCallingCode: '683',
    flag: '🇳🇺',
  },
  {
    id: '146',
    countryNameEn: 'New Zealand',
    countryCallingCode: '64',
    flag: '🇳🇿',
  },
  {
    id: '147',
    countryNameEn: 'Oman',
    countryCallingCode: '968',
    flag: '🇴🇲',
  },
  {
    id: '148',
    countryNameEn: 'Panama',
    countryCallingCode: '507',
    flag: '🇵🇦',
  },
  {
    id: '149',
    countryNameEn: 'Peru',
    countryCallingCode: '51',
    flag: '🇵🇪',
  },
  {
    id: '150',
    countryNameEn: 'French Polynesia',
    countryCallingCode: '689',
    flag: '🇵🇫',
  },
  {
    id: '151',
    countryNameEn: 'Papua New Guinea',
    countryCallingCode: '675',
    flag: '🇵🇬',
  },
  {
    id: '152',
    countryNameEn: 'Pakistan',
    countryCallingCode: '92',
    flag: '🇵🇰',
  },
  {
    id: '153',
    countryNameEn: 'Poland',
    countryCallingCode: '48',
    flag: '🇵🇱',
  },
  {
    id: '154',
    countryNameEn: 'Saint Pierre and Miquelon',
    countryCallingCode: '508',
    flag: '🇵🇲',
  },
  {
    id: '155',
    countryNameEn: 'Pitcairn',
    countryCallingCode: '64',
    flag: '🇵🇳',
  },
  {
    id: '156',
    countryNameEn: 'Puerto Rico',
    countryCallingCode: '1',
    flag: '🇵🇷',
  },
  {
    id: '157',
    countryNameEn: 'Palestine, State of',
    countryCallingCode: '970',
    flag: '🇵🇸',
  },
  {
    id: '158',
    countryNameEn: 'Portugal',
    countryCallingCode: '351',
    flag: '🇵🇹',
  },
  {
    id: '159',
    countryNameEn: 'Palau',
    countryCallingCode: '680',
    flag: '🇵🇼',
  },
  {
    id: '160',
    countryNameEn: 'Paraguay',
    countryCallingCode: '595',
    flag: '🇵🇾',
  },
  {
    id: '161',
    countryNameEn: 'Qatar',
    countryCallingCode: '974',
    flag: '🇶🇦',
  },
  {
    id: '162',
    countryNameEn: 'Réunion',
    countryCallingCode: '262',
    flag: '🇷🇪',
  },
  {
    id: '163',
    countryNameEn: 'Romania',
    countryCallingCode: '40',
    flag: '🇷🇴',
  },
  {
    id: '164',
    countryNameEn: 'Serbia',
    countryCallingCode: '381',
    flag: '🇷🇸',
  },
  {
    id: '165',
    countryNameEn: 'Russia',
    countryCallingCode: '7',
    flag: '🇷🇺',
  },
  {
    id: '166',
    countryNameEn: 'Rwanda',
    countryCallingCode: '250',
    flag: '🇷🇼',
  },
  {
    id: '167',
    countryNameEn: 'Saudi Arabia',
    countryCallingCode: '966',
    flag: '🇸🇦',
  },
  {
    id: '168',
    countryNameEn: 'Solomon Islands',
    countryCallingCode: '677',
    flag: '🇸🇧',
  },
  {
    id: '169',
    countryNameEn: 'Seychelles',
    countryCallingCode: '248',
    flag: '🇸🇨',
  },
  {
    id: '170',
    countryNameEn: 'Sweden',
    countryCallingCode: '46',
    flag: '🇸🇪',
  },
  {
    id: '171',
    countryNameEn: 'Singapore',
    countryCallingCode: '65',
    flag: '🇸🇬',
  },
  {
    id: '172',
    countryNameEn: 'Saint Helena, Ascension and Tristan da Cunha',
    countryCallingCode: '290',
    flag: '🇸🇭',
  },
  {
    id: '173',
    countryNameEn: 'Slovenia',
    countryCallingCode: '386',
    flag: '🇸🇮',
  },
  {
    id: '174',
    countryNameEn: 'Svalbard and Jan Mayen',
    countryCallingCode: '4779',
    flag: '🇸🇯',
  },
  {
    id: '175',
    countryNameEn: 'Slovakia',
    countryCallingCode: '421',
    flag: '🇸🇰',
  },
  {
    id: '176',
    countryNameEn: 'Sierra Leone',
    countryCallingCode: '232',
    flag: '🇸🇱',
  },
  {
    id: '177',
    countryNameEn: 'San Marino',
    countryCallingCode: '378',
    flag: '🇸🇲',
  },
  {
    id: '178',
    countryNameEn: 'Senegal',
    countryCallingCode: '221',
    flag: '🇸🇳',
  },
  {
    id: '179',
    countryNameEn: 'Somalia',
    countryCallingCode: '252',
    flag: '🇸🇴',
  },
  {
    id: '180',
    countryNameEn: 'Suriname',
    countryCallingCode: '597',
    flag: '🇸🇷',
  },
  {
    id: '181',
    countryNameEn: 'South Sudan',
    countryCallingCode: '211',
    flag: '🇸🇸',
  },
  {
    id: '182',
    countryNameEn: 'Sao Tome and Principe',
    countryCallingCode: '239',
    flag: '🇸🇹',
  },
  {
    id: '183',
    countryNameEn: 'El Salvador',
    countryCallingCode: '503',
    flag: '🇸🇻',
  },
  {
    id: '184',
    countryNameEn: 'Sint Maarten (Dutch part)',
    countryCallingCode: '1721',
    flag: '🇸🇽',
  },
  {
    id: '185',
    countryNameEn: 'Syrian Arab Republic',
    countryCallingCode: '963',
    flag: '🇸🇾',
  },
  {
    id: '186',
    countryNameEn: 'Chad',
    countryCallingCode: '235',
    flag: '🇹🇩',
  },
  {
    id: '187',
    countryNameEn: 'Togo',
    countryCallingCode: '228',
    flag: '🇹🇬',
  },
  {
    id: '188',
    countryNameEn: 'Thailand',
    countryCallingCode: '66',
    flag: '🇹🇭',
  },
  {
    id: '189',
    countryNameEn: 'Tajikistan',
    countryCallingCode: '992',
    flag: '🇹🇯',
  },
  {
    id: '190',
    countryNameEn: 'Tokelau',
    countryCallingCode: '690',
    flag: '🇹🇰',
  },
  {
    id: '191',
    countryNameEn: 'Timor-Leste',
    countryCallingCode: '670',
    flag: '🇹🇱',
  },
  {
    id: '192',
    countryNameEn: 'Turkmenistan',
    countryCallingCode: '993',
    flag: '🇹🇲',
  },
  {
    id: '193',
    countryNameEn: 'Tunisia',
    countryCallingCode: '216',
    flag: '🇹🇳',
  },
  {
    id: '194',
    countryNameEn: 'Tonga',
    countryCallingCode: '676',
    flag: '🇹🇴',
  },
  {
    id: '195',
    countryNameEn: 'Turkey',
    countryCallingCode: '90',
    flag: '🇹🇷',
  },
  {
    id: '196',
    countryNameEn: 'Trinidad and Tobago',
    countryCallingCode: '868',
    flag: '🇹🇹',
  },
  {
    id: '197',
    countryNameEn: 'Tuvalu',
    countryCallingCode: '688',
    flag: '🇹🇻',
  },
  {
    id: '198',
    countryNameEn: 'United Republic of Tanzania',
    countryCallingCode: '255',
    flag: '🇹🇿',
  },
  {
    id: '199',
    countryNameEn: 'Ukraine',
    countryCallingCode: '380',
    flag: '🇺🇦',
  },
  {
    id: '200',
    countryNameEn: 'Uganda',
    countryCallingCode: '256',
    flag: '🇺🇬',
  },
  {
    id: '201',
    countryNameEn: 'United States of America',
    countryCallingCode: '1',
    flag: '🇺🇸',
  },
  {
    id: '202',
    countryNameEn: 'Uruguay',
    countryCallingCode: '598',
    flag: '🇺🇾',
  },
  {
    id: '203',
    countryNameEn: 'Uzbekistan',
    countryCallingCode: '998',
    flag: '🇺🇿',
  },
  {
    id: '204',
    countryNameEn: 'Saint Vincent and the Grenadines',
    countryCallingCode: '1784',
    flag: '🇻🇨',
  },
  {
    id: '205',
    countryNameEn: 'Venezuela (Bolivarian Republic of)',
    countryCallingCode: '58',
    flag: '🇻🇪',
  },
  {
    id: '206',
    countryNameEn: 'Virgin Islands (British)',
    countryCallingCode: '1284',
    flag: '🇻🇬',
  },
  {
    id: '207',
    countryNameEn: 'Virgin Islands (U.S.)',
    countryCallingCode: '1340',
    flag: '🇻🇮',
  },
  {
    id: '208',
    countryNameEn: 'Vietnam',
    countryCallingCode: '84',
    flag: '🇻🇳',
  },
  {
    id: '209',
    countryNameEn: 'Vanuatu',
    countryCallingCode: '678',
    flag: '🇻🇺',
  },
  {
    id: '210',
    countryNameEn: 'Wallis and Futuna',
    countryCallingCode: '681',
    flag: '🇼🇫',
  },
  {
    id: '211',
    countryNameEn: 'Samoa',
    countryCallingCode: '685',
    flag: '🇼🇸',
  },
  {
    id: '212',
    countryNameEn: 'Yemen',
    countryCallingCode: '967',
    flag: '🇾🇪',
  },
  {
    id: '213',
    countryNameEn: 'Mayotte',
    countryCallingCode: '262',
    flag: '🇾🇹',
  },
  {
    id: '214',
    countryNameEn: 'South Africa',
    countryCallingCode: '27',
    flag: '🇿🇦',
  },
  {
    id: '215',
    countryNameEn: 'Zambia',
    countryCallingCode: '260',
    flag: '🇿🇲',
  },
  {
    id: '216',
    countryNameEn: 'Zimbabwe',
    countryCallingCode: '263',
    flag: '🇿🇼',
  },
  {
    id: '217',
    countryNameEn: 'Eswatini',
    countryCallingCode: '268',
    flag: '🇸🇿',
  },
  {
    id: '218',
    countryNameEn: 'North Macedonia',
    countryCallingCode: '389',
    flag: '🇲🇰',
  },
  {
    id: '219',
    countryNameEn: 'Philippines',
    countryCallingCode: '63',
    flag: '🇵🇭',
  },
  {
    id: '220',
    countryNameEn: 'Netherlands',
    countryCallingCode: '31',
    flag: '🇳🇱',
  },
  {
    id: '221',
    countryNameEn: 'United Arab Emirates',
    countryCallingCode: '971',
    flag: '🇦🇪',
  },
  {
    id: '222',
    countryNameEn: 'Republic of Moldova',
    countryCallingCode: '373',
    flag: '🇲🇩',
  },
  {
    id: '223',
    countryNameEn: 'Gambia',
    countryCallingCode: '220',
    flag: '🇬🇲',
  },
  {
    id: '224',
    countryNameEn: 'Dominican Republic',
    countryCallingCode: '1',
    flag: '🇩🇴',
  },
  {
    id: '225',
    countryNameEn: 'Sudan',
    countryCallingCode: '249',
    flag: '🇸🇩',
  },
  {
    id: '226',
    countryNameEn: "Lao People's Democratic Republic",
    countryCallingCode: '856',
    flag: '🇱🇦',
  },
  {
    id: '227',
    countryNameEn: 'Taiwan, Province of China',
    countryCallingCode: '886',
    flag: '🇹🇼',
  },
  {
    id: '228',
    countryNameEn: 'Republic of the Congo',
    countryCallingCode: '242',
    flag: '🇨🇬',
  },
  {
    id: '229',
    countryNameEn: 'Czechia',
    countryCallingCode: '420',
    flag: '🇨🇿',
  },
  {
    id: '230',
    countryNameEn: 'United Kingdom',
    countryCallingCode: '44',
    flag: '🇬🇧',
  },
  {
    id: '231',
    countryNameEn: 'Niger',
    countryCallingCode: '227',
    flag: '🇳🇪',
  },
  {
    id: '232',
    countryNameEn: 'Democratic Republic of the Congo',
    countryCallingCode: '243',
    flag: '🇨🇩',
  },
  {
    id: '233',
    countryNameEn: 'Commonwealth of The Bahamas',
    countryCallingCode: '1 242',
    flag: '🇧🇸',
  },
  {
    id: '234',
    countryNameEn: 'Cocos (Keeling) Islands',
    countryCallingCode: '61 891',
    flag: '🇨🇨',
  },
  {
    id: '235',
    countryNameEn: 'Central African Republic',
    countryCallingCode: '236',
    flag: '🇨🇫',
  },
  {
    id: '236',
    countryNameEn: 'Cook Islands',
    countryCallingCode: '682',
    flag: '🇨🇰',
  },
  {
    id: '237',
    countryNameEn: 'Falkland Islands',
    countryCallingCode: '500',
    flag: '🇫🇰',
  },
  {
    id: '238',
    countryNameEn: 'Faroe Islands',
    countryCallingCode: '298',
    flag: '🇫🇴',
  },
  {
    id: '239',
    countryNameEn: 'Territory of Heard Island and McDonald Islands',
    countryCallingCode: '672',
    flag: '🇭🇲',
  },
  {
    id: '240',
    countryNameEn: 'British Indian Ocean Territory',
    countryCallingCode: '246',
    flag: '🇮🇴',
  },
  {
    id: '241',
    countryNameEn: 'Comoros',
    countryCallingCode: '269',
    flag: '🇰🇲',
  },
  {
    id: '242',
    countryNameEn: 'Cayman Islands',
    countryCallingCode: '1 345',
    flag: '🇰🇾',
  },
  {
    id: '243',
    countryNameEn: 'Republic of the Marshall Islands',
    countryCallingCode: '692',
    flag: '🇲🇭',
  },
  {
    id: '244',
    countryNameEn: 'Commonwealth of the Northern Mariana Islands',
    countryCallingCode: '1 670',
    flag: '🇲🇵',
  },
  {
    id: '245',
    countryNameEn: 'Turks and Caicos Islands',
    countryCallingCode: '1 649',
    flag: '🇹🇨',
  },
  {
    id: '246',
    countryNameEn: 'French Southern and Antarctic Lands',
    countryCallingCode: '672',
    flag: '🇹🇫',
  },
  {
    id: '247',
    countryNameEn: 'United States Minor Outlying Islands',
    countryCallingCode: '246',
    flag: '🇺🇲',
  },
  {
    id: '248',
    countryNameEn: 'Holy See',
    countryCallingCode: '39',
    flag: '🇻🇦',
  },
];
