import React from 'react';
import Spin from 'antd/lib/spin';
import styles from './topLevelLoadingSpinner.module.scss';

const TopLevelLoadingSpinner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.spinnerContainer}>
        <div className={styles.text}>Please wait, loading...</div>
        <div className={styles.spinner}>
          <Spin />
        </div>
      </div>
    </div>
  );
};

export default TopLevelLoadingSpinner;
