import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import 'antd/dist/antd.css';

import { store, history } from './rootStore';
import App from './pages/App';
import ErrorBoundary from 'components/misc/ErrorBoundary';
import BrowserStorageHandler from 'components/misc/BrowserStorageHandler';
import datadogConfig from 'vendor/datadog';

if (window.trustedTypes && window.trustedTypes.createPolicy) {
  window.trustedTypes.createPolicy('default', {
    createHTML: (s) => s,
    createScriptURL: (s) => s,
    createScript: (s) => s,
  });
}

/*
  https://newtonx.atlassian.net/browse/SP-353

  This is custom code to handle a specific redirect.
  If the current href is:
    https://expert.salmoninsights.com?signup=asddsasdawould
  Then we redirect to:
    https://expert.newtonx.com?signup=asddsasda
  while keeping all the query parameters and url params in place
*/
const isExpertURLMatch = window.location.href.match('expert.(?!newtonx\\b)\\b\\w+.com');
if (isExpertURLMatch) {
  const matchedString = isExpertURLMatch && isExpertURLMatch[0];
  const newURL = window.location.href.replace(matchedString || '', 'expert.newtonx.com');
  window.location.href = newURL;
}

if (['production', 'staging', 'development'].includes(process.env.REACT_APP_ENV || '')) {
  datadogConfig();
}

ReactDOM.render(
  <BrowserStorageHandler>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <React.StrictMode>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </React.StrictMode>
      </ConnectedRouter>
    </Provider>
  </BrowserStorageHandler>,

  document.getElementById('root'),
);
