import Layout from 'antd/lib/layout';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import NotFound from 'pages/apps/_shared/NotFound';
import Maintenance from 'pages/apps/_shared/Maintenance';
import SurveyRouteConfig from 'pages/apps/survey/SurveyRouteConfig';
import { ALL_PATHS } from 'shared/routes/constants';

import styles from './app.module.scss';

const App = () => {
  const isMaintenanceEnabled = process.env.REACT_APP_MAINTENANCE_PAGE === 'enabled';

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Survey</title>
      </Helmet>
      <Layout className={styles.container}>
        <Switch>
          <Route
            exact
            path={ALL_PATHS}
            component={isMaintenanceEnabled ? Maintenance : SurveyRouteConfig}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </Layout>
    </>
  );
};

export default App;
