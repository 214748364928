import { GetEntry } from './types';

export const getEntry: GetEntry = () => {
  //TODO: use real api when ready
  const value = {
    is_success: true,
    redirect_url: '',
  };
  return new Promise((resolve) => setTimeout(() => resolve(value), 5000));
};
