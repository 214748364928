import React from 'react';

import { Spin, Checkbox, Title } from 'components/misc/helpers';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';
import Handlebars from 'uicontainers/apps/survey/_shared/handlebars/Handlebars';
import { useOnFeatureFlagChange, useSurveyFeatureFlagsState } from './hooks';
import startCase from 'lodash/startCase';
import toPairs from 'lodash/toPairs';

export const SurveyFeatureFlags = () => {
  const onChange = useOnFeatureFlagChange();
  const surveyFeatureFlagsState = useSurveyFeatureFlagsState();
  if (!surveyFeatureFlagsState.flags || process.env.REACT_APP_FLAGS_UI_ENABLED === 'false')
    return null;
  return (
    <Spin spinning={false} size="large">
      <VerticalSpaceContainer>
        <Title>Feature Flags</Title>
        {toPairs(surveyFeatureFlagsState.flags).map(([flagName, flagValue]) => (
          <div key={`feature-flag-${flagName}`}>
            <Checkbox checked={flagValue} onChange={onChange(flagName)}>
              {startCase(flagName)}
            </Checkbox>
          </div>
        ))}
        <Handlebars />
      </VerticalSpaceContainer>
    </Spin>
  );
};

export default SurveyFeatureFlags;
