import React from 'react';

import { Space } from 'components/misc/helpers';
import HtmlRenderer from 'components/misc/HtmlRenderer';
import { useSurveyContent } from 'shared/hooks';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';
import Heading from 'uicontainers/_shared/Heading';
import Handlebars from 'uicontainers/apps/survey/_shared/handlebars/Handlebars';
import NXButton from 'components/misc/NXButton';

import { removeAllHandlebars } from 'shared/helpers/handlebar';

import { Props } from './types';

export const Qualification = (props: Props) => {
  const { continueClickHandler, notQualifyClickHandler } = props;

  const { surveyContent } = useSurveyContent();

  return (
    <VerticalSpaceContainer>
      {/* heading */}
      <Heading text={removeAllHandlebars(surveyContent?.content?.heading ?? null) || ''} />

      {/* subheading */}
      <HtmlRenderer
        content={removeAllHandlebars(surveyContent?.content?.subheading ?? null) || ''}
      />

      <Space wrap={true} style={{ margin: '0px' }}>
        <NXButton
          type="primary"
          onClick={continueClickHandler}
          data-test-id="qualification-btn-continue"
        >
          Continue
        </NXButton>
        <NXButton onClick={notQualifyClickHandler} data-test-id="qualification-btn-do-not-qualify">
          I do not qualify
        </NXButton>
      </Space>

      <Handlebars />
    </VerticalSpaceContainer>
  );
};

export default Qualification;
