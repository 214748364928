import { MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import { RootState } from 'rootReducer';
import { useActions } from 'shared/hooks';

import { showCopiedReferralLink } from 'uicontainers/apps/survey/_shared/handlebars/_shared/actions';
import { selectPaymentInformationResponse, selectCopiedReferralLink } from './selectors';

import { ParamTypes } from './types';

export const useHandlebarsActions = () =>
  useActions({
    showCopiedReferralLink,
  });

export const useHandlebarsSelectors = () =>
  useSelector((state: RootState) => {
    return {
      paymentInformationResponse: selectPaymentInformationResponse(state),
      copiedReferralLink: selectCopiedReferralLink(state),
    };
  });

export const useReferralLink = () => {
  const { uuid } = useParams<ParamTypes>();
  const { protocol, host } = window.location;
  return `${protocol}//${host}/referrals/e/${uuid}`;
};

export const useHandleCopyLink: () => MouseEventHandler = () => {
  const { showCopiedReferralLink } = useHandlebarsActions();
  const referralLink = useReferralLink();

  return (event) => {
    event.stopPropagation();

    copy(referralLink);

    showCopiedReferralLink(true);

    setTimeout(() => {
      showCopiedReferralLink(false);
    }, 2000);
  };
};

export const useRedirect = () => {
  const { paymentInformationResponse } = useHandlebarsSelectors();

  const redirectTo = paymentInformationResponse?.redirect_to ?? null;

  if (redirectTo) {
    window.location.href = redirectTo;
  }
};
