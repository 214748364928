import { Record, RecordOf } from 'immutable';

import { LinkedInPostAuth } from 'api/linkedIn/types';

export const linkedInPostAuthInitializer = (
  linkedInPostAuth: LinkedInPostAuth,
): RecordOf<LinkedInPostAuth> => {
  const defaultValues: LinkedInPostAuth = {
    redirect_to: 'https://www.client.com/surveys/345678',
  };
  return Record<LinkedInPostAuth>(defaultValues)(linkedInPostAuth);
};
