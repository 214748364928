import React from 'react';

import paymentPending from 'assets/images/paymentPending.svg';
import BlockContainer from 'uicontainers/_shared/container/BlockContainer';
import { Props } from 'uicontainers/_shared/container/BlockContainer/types';

const PaymentError = () => {
  const containerProps: Props = {
    header: 'There was an error on our side.',
    subHeaders: [
      {
        content: `Please try again in 15 minutes.  If the issue is still happening, please reach out to us at payments@newtonxcommunity.com`,
      },
    ],
    image: {
      path: paymentPending,
      altText: 'Payment Error',
    },
  };

  return <BlockContainer {...containerProps}></BlockContainer>;
};

export default PaymentError;
