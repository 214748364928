import { useSelector } from 'react-redux';

import { RootState } from 'rootReducer';

import { selectPaymentInformationResponse } from './selectors';

import {} from './selectors';

export const useFormSubmittedSelectors = () =>
  useSelector((state: RootState) => {
    return {
      paymentInformationResponse: selectPaymentInformationResponse(state),
    };
  });
