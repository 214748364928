import React from 'react';

import surveyClosed from 'assets/images/surveyClosed.svg';
import BlockContainer from 'uicontainers/_shared/container/BlockContainer';
import { Props } from 'uicontainers/_shared/container/BlockContainer/types';

const ReferralsClosed = () => {
  const containerProps: Props = {
    header: 'This referral link is no longer available',
    subHeaders: [
      {
        content: "Thanks for your interest but we aren't accepting any more applicants right now",
      },
    ],
    image: {
      path: surveyClosed,
      altText: 'Referrals closed',
    },
  };

  return <BlockContainer {...containerProps}></BlockContainer>;
};

export default ReferralsClosed;
