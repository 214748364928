import { Record, RecordOf } from 'immutable';

import { SurveyContent, Content, SendPaymentInformationResponse, UISpec } from './types';

export const surveyContentInitializer = (surveyContent: SurveyContent): RecordOf<SurveyContent> => {
  const defaultValues: SurveyContent = {
    alert_level: null,
    alert_msg: null,
    content: null,
    is_allowed: false,
    redirect_to: null,
    current_flow: null,
    subject: null,
    subject_type: null,
    ui_specs: null,
    block_type: 'ENTRY',
  };

  const newSurveyContent = {
    ...surveyContent,
    ...(surveyContent.content ? { content: Record<Content>(surveyContent.content)() } : {}),

    ...(surveyContent.ui_specs ? { ui_specs: Record<UISpec>(surveyContent.ui_specs)() } : {}),
  };

  return Record<SurveyContent>(defaultValues)(newSurveyContent);
};

export const paymentInformationResponseInitializer = (
  response: SendPaymentInformationResponse,
): RecordOf<SendPaymentInformationResponse> => {
  const defaultValues: SendPaymentInformationResponse = {
    alert_level: null,
    alert_msg: null,
    redirect_to: null,
  };

  return Record<SendPaymentInformationResponse>(defaultValues)(response);
};
