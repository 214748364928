import surveyAPI from 'api/base';

import { SendPaymentInformation, GetContent } from './types';

export const sendPaymentInformation: SendPaymentInformation = (url, data) => {
  const { email, optIn, phone, paymentUrl } = data;
  const requestData = {
    request_url: url,
    email,
    phone,
    ...(typeof optIn === 'boolean' ? { did_opt_in: optIn } : {}),
  };

  return surveyAPI.post(paymentUrl, requestData);
};

export const getContent: GetContent = (url) => {
  return surveyAPI.get(`/surveys:content/?survey_url=${url}`);

  // return Promise.resolve({
  //   is_allowed: true,
  //   content: {
  //     heading:
  //       'some heading [paymentEmailEntry] [referralLinkWithButton] [referralLinkReferralProgramButton]',
  //     subheading: 'some sub heading',
  //     terms_message: 'some message',
  //     opt_in_message: 'some message',
  //     end_message: 'some message',
  //     referral_message: 'some message',
  //   },
  //   redirect_to: null,
  //   alert_msg: 'some message',
  //   alert_level: 'error',
  //   subject: 'some sub',
  //   subject_type: 'referee',
  //   current_flow: 'MAIN',
  //   ui_specs: {
  //     should_show_opt_in: true,
  //     should_show_terms: true,
  //   },
  //   block_type: 'CUSTOM',
  // } as SurveyContent);
};
