import { Record, RecordOf } from 'immutable';

import { Entry } from 'api/entry/types';

export const entryInitializer = (entry: Entry): RecordOf<Entry> => {
  const defaultValues: Entry = {
    is_success: false,
    redirect_url: '',
  };
  return Record<Entry>(defaultValues)(entry);
};
