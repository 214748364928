import { LinkedinOutlined } from '@ant-design/icons';
import React from 'react';

import NXButton from 'components/misc/NXButton';

export const renderElement = ({ onClick, disabled }: any) => { //eslint-disable-line
  return (
    // @ts-ignore
    <NXButton icon={<LinkedinOutlined />} type="primary" onClick={onClick} disabled={disabled}>
      Sign in with LinkedIn
    </NXButton>
  );
};
