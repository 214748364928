import * as surveys from 'api/surveys';
import { SurveyContent } from 'api/surveys/types';
import { createActionFactories } from 'shared/helpers/actions';

import { GetContentPayload } from './types';

const { createAsync } = createActionFactories('_resources/survey');

export const getContent = createAsync<GetContentPayload, SurveyContent>(
  'GET_SURVEY_CONTENT',
  (payload) => {
    const { url } = payload;

    return surveys.getContent(url);
  },
);
