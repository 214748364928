import AntdBreadcrumb from 'antd/lib/breadcrumb/Breadcrumb';
import AntdCheckbox from 'antd/lib/checkbox/Checkbox';
import AntdDatePicker from 'antd/lib/date-picker';
import AntdDropdown from 'antd/lib/dropdown';
import AntdForm from 'antd/lib/form';
import AntdInput from 'antd/lib/input';
import AntdInputNumber from 'antd/lib/input-number';
import AntdLayout from 'antd/lib/layout';
import AntdList from 'antd/lib/list';
import AntdMenu from 'antd/lib/menu';
import AntdPopover from 'antd/lib/popover';
import AntdRadio from 'antd/lib/radio';
import AntdSelect from 'antd/lib/select';
import AntdSteps from 'antd/lib/steps';
import AntdTabs from 'antd/lib/tabs';
import AntdTooltip from 'antd/lib/tooltip';
import AntdTypography from 'antd/lib/typography';
import AntdAlert from 'antd/lib/alert';
import AntdButton from 'antd/lib/button';
import AntdSkeleton from 'antd/lib/skeleton';
import AntdSpace from 'antd/lib/space';
import AntdSpin from 'antd/lib/spin';

import { TooltipProps as AntdTooltipProps } from 'antd/lib/tooltip/index';

import { Store as AntdFormStore } from 'antd/lib/form/interface';

export const Tabs = AntdTabs;
export const TabPane = Tabs.TabPane;

export const Steps = AntdSteps;
export const Step = Steps.Step;

export const Select = AntdSelect;
export const SelectOption = Select.Option;

export const Layout = AntdLayout;
export const Header = Layout.Header;
export const Sider = Layout.Sider;
export const Content = Layout.Content;
export const Footer = Layout.Footer;

export const Menu = AntdMenu;
export const SubMenu = Menu.SubMenu;
export const MenuItem = Menu.Item;

export const Breadcrumb = AntdBreadcrumb;
export const BreadcrumbItem = Breadcrumb.Item;

export const OptGroup = Select.OptGroup;
export const Option = Select.Option;

export const Form = AntdForm;
export const FormItem = AntdForm.Item;
export const useForm = AntdForm.useForm;

export type FormStore = AntdFormStore;

export const Popover = AntdPopover;

export const Tooltip = AntdTooltip;

export type TooltipProps = AntdTooltipProps;

export const Radio = AntdRadio;
export const RadioGroup = AntdRadio.Group;

export const Input = AntdInput;
export const Search = AntdInput.Search;

export const DatePicker = AntdDatePicker;

export const InputNumber = AntdInputNumber;

export const Checkbox = AntdCheckbox;

export const List = AntdList;
export const ListItem = AntdList.Item;
export const ListItemMeta = AntdList.Item.Meta;

export const Dropdown = AntdDropdown;

export const Text = AntdTypography.Text;
export const Title = AntdTypography.Title;
export const Paragraph = AntdTypography.Paragraph;
export const Link = AntdTypography.Link;

export const Alert = AntdAlert;

export const Button = AntdButton;
export const Skeleton = AntdSkeleton;
export const SkeletonInput = AntdSkeleton.Input;
export const SkeletonButton = AntdSkeleton.Button;
export const Space = AntdSpace;
export const Spin = AntdSpin;
