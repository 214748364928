import React from 'react';

import { Spin } from 'components/misc/helpers';
import Details from 'uicontainers/apps/survey/verification/contents/Details';
import Qualification from 'uicontainers/apps/survey/verification/contents/Qualification';
import SingIn from 'uicontainers/apps/survey/verification/contents/SignIn';
import DoNotQualify from 'uicontainers/apps/survey/verification/contents/DoNotQualify';

import { handleContinue, handleDoNotHaveLinkedIn, handleDoNotQualify } from './helpers';
import { useVerificationActions, useVerificationSelectors } from './hooks';

export const Home = () => {
  const [isQualified, setIsQualified] = React.useState(false);
  const [doNotQualified, setDoNotQualified] = React.useState(false);
  const [isMoreDetailsEnabled, setIsMoreDetailsEnabled] = React.useState(false);

  const { isVerifyingLinkedInOAuth, isPostingDetails } = useVerificationSelectors();
  const { verifyLinkedInOAuth, postDetails } = useVerificationActions();

  const qualifedSection = !isQualified && !isMoreDetailsEnabled && !doNotQualified && (
    <Qualification
      continueClickHandler={handleContinue(setIsQualified)}
      notQualifyClickHandler={handleDoNotQualify(setDoNotQualified)}
    />
  );

  const signIn = isQualified && !isMoreDetailsEnabled && (
    <SingIn
      haveLinkedInClickHandler={verifyLinkedInOAuth}
      doNotHaveLinkedInClickHandler={handleDoNotHaveLinkedIn(setIsMoreDetailsEnabled)}
    />
  );

  const doNotQualify = !isQualified && doNotQualified && <DoNotQualify />;

  const details = isMoreDetailsEnabled && <Details formSubmitHandler={() => postDetails} />;

  return (
    <Spin spinning={isVerifyingLinkedInOAuth || isPostingDetails} size="large">
      {qualifedSection}
      {signIn}
      {details}
      {doNotQualify}
    </Spin>
  );
};

export default Home;
