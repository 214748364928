import React from 'react';
import { useParams } from 'react-router-dom';

import { LinkedInPostAuthFormData } from 'api/linkedIn/types';
import { FormItem, Input, Link, Paragraph, Spin, Text } from 'components/misc/helpers';
import NXButton from 'components/misc/NXButton';
import NXForm from 'components/misc/NXForm';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';
import Heading from 'uicontainers/_shared/Heading';

import { EMAIL_RULES, formItemLayout, LINKEDIN_RULES } from './constants';
import { linkedInPopUp, redirectToUrl, validateMessages } from './helpers';
import { useHandleSubmit, useLinkedInPostAuthSelectors } from './hooks';
import { ParamTypes } from './types';

export const LinkedInPostAuth = () => {
  const { selectIsPostingLinkedInAuth, redirect_to } = useLinkedInPostAuthSelectors();
  const handleSubmit = useHandleSubmit();

  const onSubmit = (formData: LinkedInPostAuthFormData) => handleSubmit(formData);

  // TODO: before removing, wait until api implementation complete
  const { uuid } = useParams<ParamTypes>();

  React.useEffect(() => {
    redirectToUrl(redirect_to as string);
  }, [redirect_to]);

  return (
    <>
      <Spin spinning={selectIsPostingLinkedInAuth} size="large">
        <VerticalSpaceContainer>
          <Heading text="One more thing" />
          <Paragraph>
            Last step to check if you’re a fit, we just need your public LinkedIn URL:
          </Paragraph>
          <div>
            <NXForm
              initialValues={{
                seid: uuid,
              }}
              {...formItemLayout}
              layout="vertical"
              onFinish={onSubmit}
              validateMessages={validateMessages}
            >
              <FormItem
                label="Your Public LinkedIn"
                name="linkedin_profile_url"
                rules={LINKEDIN_RULES}
                extra={
                  <Text>
                    If you don’t know your LinkedIn URL,{' '}
                    <Link onClick={linkedInPopUp} underline={false}>
                      Click here
                    </Link>{' '}
                    to see your URL (and paste it above).
                  </Text>
                }
              >
                <Input />
              </FormItem>

              <FormItem name="email" label="Email" rules={EMAIL_RULES}>
                <Input />
              </FormItem>

              <FormItem hidden={true} name="seid"></FormItem>

              <FormItem>
                <NXButton type="primary" htmlType="submit">
                  Continue
                </NXButton>
              </FormItem>
            </NXForm>
          </div>
        </VerticalSpaceContainer>
      </Spin>
    </>
  );
};

export default LinkedInPostAuth;
