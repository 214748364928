import surveyAPI, { linkedInAPI } from 'api/base';

import { PostDetails, VerifyLinkedInOAuthQueryParams } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const verifyLinkedInOAuth: any = (params: VerifyLinkedInOAuthQueryParams) => {
  const { seid, code, redirect_uri } = params;
  return linkedInAPI
    .get(`/oauth/linkedin/access/?seid=${seid}&code=${code}&redirect_uri=${redirect_uri}`)
    .then((response) => {
      // TODO: need to verify status code
      window.location.href = response.data;
    });
};

export const postDetails: PostDetails = (data) => {
  return surveyAPI.post('/surveys:manual_verification/', data);
};
