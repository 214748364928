import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

export const getDomainNameFromUrl = (url: string) => {
  if (url) {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match !== null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0)
      return match[2];
  } else {
    return '';
  }
};

// @ts-ignore
export const formatExceptionForCapture = (error) => {
  if (isObject(error)) {
    return new Error(JSON.stringify(error));
  } else if (isString(error)) {
    return new Error(error);
  } else {
    return new Error('Error: N/A Could Not Figure Out Reason');
  }
};

export const getDomainNameFromEmail = (email: string) => {
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };
  if (validateEmail(email)) {
    return email.split('@')[1];
  } else {
    return '';
  }
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
