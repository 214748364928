import * as linkedIn from 'api/linkedIn';
import { LinkedInPostAuthFormData, LinkedInPostAuthResponse } from 'api/linkedIn/types';
import { createActionFactories } from 'shared/helpers/actions';

const { createAsync } = createActionFactories('_resources/linkedIn');

export const postLinkedInAuth = createAsync<LinkedInPostAuthFormData, LinkedInPostAuthResponse>(
  'POST_LINKEDIN_AUTH',
  async (params) => {
    return await linkedIn.postLinkedInAuth(params);
  },
);
