import React from 'react';
import { isImmutable } from 'immutable';
import antdNotification from 'antd/lib/notification';
import { List, fromJS } from 'immutable';
import { AxiosError } from 'axios';
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

export interface NotificationParams {
  type: 'success' | 'info' | 'warning' | 'error';
  description: string;
  title?: string;
}

export const formatValidationErrorMessage = (error: { errorFields: { errors: string[] }[] }) => {
  let message = '';
  error.errorFields.forEach((e) => {
    const errors = e.errors || [];
    errors.forEach((m) => {
      message += m + '\n';
    });
  });
  return message;
};

export const formatHTTPErrorTitle = (error: AxiosError) => {
  const statusCode = error?.response?.status;
  if (!statusCode) {
    return 'Connectivity Lost';
  }
  return `HTTP Error: ${statusCode}`;
};

export const formatHTTPErrorMessage = (error: AxiosError) => {
  let message = error.message;
  const data = fromJS(error?.response?.data || {});
  if (isImmutable(data) && !data?.isEmpty()) {
    const array: string[] = [];
    //@ts-ignore
    data.forEach((value: List<string>, field: string) => {
      if (value?.join) {
        array.push(`${field} - ${value?.join('-')}`);
      }
    });
    message = array.join('\n');
  }
  return message;
};

export const notification = (params: NotificationParams) => {
  const { type, description, title } = params;
  const icons = {
    // @ts-ignore
    success: <CheckCircleOutlined style={{ color: 'green' }} />,
    // @ts-ignore
    error: <CloseCircleOutlined style={{ color: 'red' }} />,
    // @ts-ignore
    info: <InfoCircleOutlined style={{ color: 'gray' }} />,
    // @ts-ignore
    warning: <ExclamationCircleOutlined style={{ color: 'yellow' }} />,
  };
  const defaultTitle = {
    success: 'Success',
    error: 'Error',
    info: 'Info',
    warning: 'Warning',
  };
  antdNotification[type]({
    message: title || defaultTitle[type],
    description,
    placement: 'bottomRight',
    icon: icons[type],
  });
};
