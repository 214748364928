import { RecordOf } from 'immutable';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';

import { surveyContentInitializer } from 'api/surveys/helpers';
import { getInitialState } from 'shared/helpers/reducer';

import * as actions from './actions';
import { SurveyState } from './types';

const defaultValues: SurveyState = {
  content: null,
};

export const initialState = getInitialState<SurveyState>(defaultValues);

export default (
  state: RecordOf<SurveyState> = initialState,
  action: Action,
): RecordOf<SurveyState> => {
  if (isType(action, actions.getContent.async.started)) {
    return state.set('content', null);
  } else if (isType(action, actions.getContent.async.done)) {
    const response = action.payload.result;

    return state.set('content', surveyContentInitializer(response));
  }

  return state;
};
