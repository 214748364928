import { RecordOf } from 'immutable';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';

import { onboardingInitializer } from 'api/onboarding/helpers';
import { OnboardingResponse } from 'api/onboarding/types';
import { getInitialState } from 'shared/helpers/reducer';

import * as actions from './actions';
import { OnboardingState } from './types';

const defaultValues: OnboardingState = {
  onboardingResult: null,
};

export const initialState = getInitialState<OnboardingState>(defaultValues);

export default (
  state: RecordOf<OnboardingState> = initialState,
  action: Action,
): RecordOf<OnboardingState> => {
  if (isType(action, actions.postOnboarding.async.done)) {
    const response: OnboardingResponse = action.payload.result;
    return state.set('onboardingResult', onboardingInitializer(response));
  }

  return state;
};
