import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'shared/routes/constants';
import LinkedInPostAuthUI from 'uicontainers/apps/survey/LinkedInPostAuth';

export const LinkedInPostAuth = () => {
  return (
    <>
      <Switch>
        <Route path={ROUTES.SURVEY.PATHS.LINKEDIN_POSTAUTH} component={LinkedInPostAuthUI} />
      </Switch>
    </>
  );
};

export default LinkedInPostAuth;
