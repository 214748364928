import { useCallback } from 'react';
import { useHistory } from 'react-router';

export const useGoBack = () => {
  const history = useHistory();

  return useCallback(() => {
    history.goBack();
  }, [history]);
};
