export const handleContinue = (
  stateUpdater: React.Dispatch<React.SetStateAction<boolean>>,
) => () => {
  stateUpdater(true);
};

export const handleDoNotHaveLinkedIn = (
  stateUpdater: React.Dispatch<React.SetStateAction<boolean>>,
) => () => {
  stateUpdater(true);
};

export const handleDoNotQualify = (
  stateUpdater: React.Dispatch<React.SetStateAction<boolean>>,
) => () => {
  stateUpdater(true);
};
