import React, { FC } from 'react';

import { Form } from 'components/misc/helpers';

import { Props } from './types';

const NXForm: FC<Props> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Form {...otherProps} size="large">
      {children}
    </Form>
  );
};

export default NXForm;
