import { combineReducers as _ } from 'redux';

import entryHomeReducer from 'uicontainers/apps/survey/entry/Home/reducer';
import onboardingHomeReducer from 'uicontainers/apps/survey/onboarding/Home/reducer';
import linkedInPostAuthReducer from 'uicontainers/apps/survey/LinkedInPostAuth/reducer';
import verificationHomeReducer from 'uicontainers/apps/survey/verification/Home/reducer';
import topLevelLoadingSpinnerReducer from 'uicontainers/apps/survey/_shared/TopLevelLoadingSpinner/reducer';
import Handlebars from 'uicontainers/apps/survey/_shared/handlebars/_shared/reducer';

export const surveyReducer = _({
  _shared: _({
    topLevelLoadingSpinner: topLevelLoadingSpinnerReducer,
  }),
  onboarding: _({
    home: onboardingHomeReducer,
  }),
  linkedIn: linkedInPostAuthReducer,
  entry: _({
    home: entryHomeReducer,
  }),
  verification: _({
    home: verificationHomeReducer,
  }),
  Handlebars,
});

export default surveyReducer;
