import { datadogRum } from '@datadog/browser-rum';

export default () => {
  window.onunhandledrejection = (data: PromiseRejectionEvent) => {
    datadogRum.addError(new Error(data.reason));
  };

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_NAME,
    version: process.env.REACT_APP_PANGEA_GIT_HASH,
    env: process.env.REACT_APP_ENV,
    sampleRate: 100,
    sessionReplaySampleRate: 50,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    useSecureSessionCookie: true,
  });
  datadogRum.startSessionReplayRecording();
};
