import { RecordOf } from 'immutable';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';

import { linkedInPostAuthInitializer } from 'api/linkedIn/helpers';
import { LinkedInPostAuthResponse } from 'api/linkedIn/types';
import { getInitialState } from 'shared/helpers/reducer';

import * as actions from './actions';
import { LinkedInPostAuthState } from './types';

const defaultValues: LinkedInPostAuthState = {
  linkdedInPostAuthResult: null,
};

export const initialState = getInitialState<LinkedInPostAuthState>(defaultValues);

export default (
  state: RecordOf<LinkedInPostAuthState> = initialState,
  action: Action,
): RecordOf<LinkedInPostAuthState> => {
  if (isType(action, actions.postLinkedInAuth.async.done)) {
    const response: LinkedInPostAuthResponse = action.payload.result;
    return state.set('linkdedInPostAuthResult', linkedInPostAuthInitializer(response));
  }
  return state;
};
