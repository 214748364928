import { useSelector } from 'react-redux';

import { RootState } from 'rootReducer';
import { useActions } from 'shared/hooks';
import { postDetails } from 'store/verification/actions';

import { DetailsFormData } from 'api/verification/types';
import { isValidWorkEmail } from 'uicontainers/apps/survey/verification/contents/Details/helpers';

import {
  selectIsPostingDetails,
  selectSuccessRedirectUrl,
  selectManualVerificationError,
} from './selectors';

export const useVerificationActions = () =>
  useActions({
    postDetails,
  });

export const useHandleSubmit = () => {
  const { postDetails: postAction } = useVerificationActions();
  return (formData: DetailsFormData, showWaring: React.Dispatch<React.SetStateAction<boolean>>) => {
    const isValidEmail = isValidWorkEmail(formData);
    showWaring(isValidEmail);
    if (isValidEmail) {
      postAction(formData);
    }
  };
};

export const useOnDomainChange = (showWaring: React.Dispatch<React.SetStateAction<boolean>>) => {
  return (formData: DetailsFormData) => {
    return showWaring(isValidWorkEmail(formData));
  };
};

export const useOnAlertClose = (showWaring: React.Dispatch<React.SetStateAction<boolean>>) => {
  return () => {
    return showWaring(true);
  };
};

export const useVerificationSelectors = () =>
  useSelector((state: RootState) => {
    return {
      isPostingDetails: selectIsPostingDetails(state),
      redirect_to: selectSuccessRedirectUrl(state),
      manualVerificationError: selectManualVerificationError(state),
    };
  });
