import * as payment from 'api/payment';
import { PaymentResponse } from 'api/payment/types';
import { createActionFactories } from 'shared/helpers/actions';

const { createAsync } = createActionFactories('_resources/payment');
export const { create } = createActionFactories('_resources/payment');

export const resetErrorCount = create<void>('RESET_ERROR_COUNT');

export const getPayment = createAsync<{ uuid: string; paymentId?: string }, PaymentResponse>(
  'GET_PAYMENT',
  async (params) => {
    return await payment.getPayment(params.uuid, params?.paymentId);
  },
);
