import { LinkedInFailure, LinkedInSuccess, Props } from './types';

export const useHandleSuccess = (props: Props, uuid: string, redirectUrl: string) => {
  const { haveLinkedInClickHandler } = props;
  return (data: LinkedInSuccess) => {
    haveLinkedInClickHandler({
      seid: uuid,
      code: data.code,
      redirect_uri: redirectUrl,
    });
  };
};

export const useHandleFailure = () => {
  return (error: LinkedInFailure) => {
    console.error(error.errorMessage);
    window.location.href = '/error';
  };
};
