import { RecordOf } from 'immutable';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';

import { entryInitializer } from 'api/entry/helpers';
import { EntryResponse } from 'api/entry/types';
import { getInitialState } from 'shared/helpers/reducer';

import * as actions from './actions';
import { EntryState } from './types';

const defaultValues: EntryState = {
  entryResult: null,
};

export const initialState = getInitialState<EntryState>(defaultValues);

export default (
  state: RecordOf<EntryState> = initialState,
  action: Action,
): RecordOf<EntryState> => {
  if (isType(action, actions.getEntry.async.done)) {
    const response: EntryResponse = action.payload.result;
    return state.set('entryResult', entryInitializer(response));
  }

  return state;
};
