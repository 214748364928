import React, { FC } from 'react';

import { FormItem, Input } from 'components/misc/helpers';
import CountrySelector from 'components/form/phoneNumberFormItem/CountrySelector';

import { PhoneNumberFormItemProps } from './types';

const PhoneNumberFormItem: FC<PhoneNumberFormItemProps> = (props) => {
  const {
    label,
    name,
    countrySelectorName,
    wrapperCol,
    required = false,
    className = '',
    rules = [],
    disabled,
  } = props;

  return (
    <FormItem
      label={label}
      name={name}
      required={required}
      className={className}
      wrapperCol={wrapperCol}
      rules={[
        ...rules,
        () => ({
          validator(_, value) {
            if (!value || (value as string).match(/^([0-9]){4,15}$/)) {
              return Promise.resolve();
            }

            return Promise.reject('Please input a valid phone number');
          },
        }),
      ]}
    >
      <Input
        autoComplete="new-password"
        addonBefore={<CountrySelector name={countrySelectorName} />}
        disabled={disabled}
      />
    </FormItem>
  );
};

export default PhoneNumberFormItem;
