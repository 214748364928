import * as surveys from 'api/surveys';
import { SendPaymentInformationResponse } from 'api/surveys/types';
import { createActionFactories } from 'shared/helpers/actions';

import { SendPaymentInformationParams } from './types';

const { create, createAsync } = createActionFactories(
  'uicontainers/apps/survey/handlebars/_shared',
);

export const showTextTips = create<void>('SHOW_UPCOMING_CONSULTATIONS_TEXT_TIPS');
export const showCopiedReferralLink = create<boolean>('SHOW_COPIED_REFFERRAL_LINK');

export const sendPaymentInformation = createAsync<
  SendPaymentInformationParams,
  SendPaymentInformationResponse
>('SEND_PAYMENT_INFORMATION', (params) => {
  const { url, email, phone, optIn, paymentUrl } = params;

  return surveys.sendPaymentInformation(url, { email, phone, optIn, paymentUrl });
});
