import { datadogRum } from '@datadog/browser-rum';

import {
  formatHTTPErrorMessage,
  formatHTTPErrorTitle,
  notification,
} from 'shared/helpers/notifications';

import { axiosCreate, replaceURLDomainToBrowserDomain } from './helpers';
import { InterceptErrorResponse, InterceptSuccessResponse } from './types';
import { formatExceptionForCapture } from '../../shared/helpers/misc';

export const interceptSuccessResponse: InterceptSuccessResponse = (response) => {
  if (response?.data) {
    return JSON.parse(JSON.stringify(response.data).replace(/localhost/g, 'localhost'));
  }
  return {};
};

export const linkedInSuccessResponseIntercept: InterceptSuccessResponse = (response) => {
  return response;
};

export const interceptErrorResponse: InterceptErrorResponse = (error) => {
  const statusCode = error?.response?.status;
  const { statusText, status, data } = (error && error.response) || {};
  const { response: res, responseURL } = (error && error.request) || {};
  const extra = {
    status,
    statusText,
    data,
    response: res,
    requestURL: responseURL,
  };

  if (statusCode === 401 || statusCode === 403) {
    console.dir(error);
    console.error(error);
    datadogRum.addError(formatExceptionForCapture(error), extra);
    notification({
      type: 'error',
      title: formatHTTPErrorTitle(error),
      description: formatHTTPErrorMessage(error),
    });
    return Promise.reject(error);
  }

  if ([undefined, 500, 502, 503].includes(error?.response?.status)) {
    datadogRum.addError(formatExceptionForCapture(error), extra);
    notification({
      type: 'error',
      title: formatHTTPErrorTitle(error),
      description: formatHTTPErrorMessage(error),
    });
    console.dir(error);
    console.error(error);
    return Promise.reject(error);
  }
  notification({
    type: 'error',
    title: formatHTTPErrorTitle(error),
    description: formatHTTPErrorMessage(error),
  });
  datadogRum.addError(formatExceptionForCapture(error), extra);
  console.dir(error);
  console.error(error);
  if (statusCode === 400) {
    return Promise.reject(data);
  }
  if (statusCode === 404) {
    window.location.assign('/error');
  }
  return Promise.reject(error);
};

const baseAPI = axiosCreate({
  baseURL: replaceURLDomainToBrowserDomain(process.env.REACT_APP_API_ENDPOINT as string),
})(interceptSuccessResponse, interceptErrorResponse);

export const linkedInAPI = axiosCreate({
  baseURL: replaceURLDomainToBrowserDomain(process.env.REACT_APP_API_ENDPOINT as string),
})(linkedInSuccessResponseIntercept, interceptErrorResponse);

export default baseAPI;
