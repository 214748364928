import { Record, RecordOf } from 'immutable';

import { Onboarding } from 'api/onboarding/types';

export const onboardingInitializer = (onboarding: Onboarding): RecordOf<Onboarding> => {
  const defaultValues: Onboarding = {
    redirect_to: 'https://www.client.com/surveys/345678',
  };
  return Record<Onboarding>(defaultValues)(onboarding);
};
